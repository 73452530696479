import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { omitBy, isNil, debounce } from 'lodash';
import {withRouter } from 'withRouter'
import InfiniteScroll from 'react-infinite-scroller';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { isRoleHigherThan } from 'utils/common';
import {
  getDetailRole,
} from 'containers/Settings/AccessRole/selectors';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { FormattedMessage } from 'react-intl';
import { routeMapping } from 'routes';
import { Users } from 'react-feather';
import slugify from 'react-slugify';
import Pagination from 'components/Pagination';

import SearchField from 'components/SingleSearch';
import { EnumType } from 'json-to-graphql-query';
import ListSelectedItems from 'components/ListSelectedItems';
import Filter from 'components/Filter';
import { getFilterData } from 'components/Filter/filter';
import groupSaga from 'containers/Settings/UserGroup/saga';
import groupReducer from 'containers/Settings/UserGroup/reducer';
import messages from 'utils/messages';
import SecondaryModal from 'components/SecondaryModal';
import NotFound from 'containers/NotFoundPage';
import {
  getTrackerInfo,
} from 'containers/Settings/Workspace/selectors';
import {
  updateStartedTrackerRequest,
} from 'containers/Settings/Workspace/actions';
import { fetchUserRequest } from './actions';
import UserTable from './Table';
import filterColumns from './filter';
import Card from './Card';
import { CARD_VIEW, TABLE_VIEW } from './constants';
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectFetchUser,
} from './selectors';
import saga from './saga';
import roleSaga from '../AccessRole/saga';
import roleReducer from '../AccessRole/reducer';
import reducer from './reducer';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {
        key: 'firstName',
        direction: 'asc',
      },
      resetFilter: false,
      filter: {
        single: [],
        range: [],
      },
      filteredColumns: [],
      search: null,
      searchName: null,
      showModal: true,
      activeView: TABLE_VIEW,
      offset: DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };

    this.debouncedLoadMore = debounce(this.loadMore, 250);
  }

  onSorting = sort => this.setState(
    {
      sort,
      offset: 0,
      limit: this.state.limit,
    },
    this.getUserList,
  );

  onCloseModal = () => {
    this.onSkip();
    this.setState({ showModal: false });
  };

  /* Filter End */
  isMore = () => {
    const { userList, loading } = this.props;
    if (loading) {
      return false;
    }

    return userList && userList.count > userList.data.length;
  };

  getUserList = () => {
    const payload = {
      range: this.state.filter.range,
      single: this.state.searchName
        ? [...this.state.filter.single, this.state.search]
        : [...this.state.filter.single],
    };

    const args = {
      limit: this.state.limit,
      includeDemoData: this.props.workspace.demoModeEnabled,
      offset: this.state.currentPage <= 1 ? 0 : (this.state.currentPage - 1) * this.state.limit,
      query: payload,
      sort: {
        field: this.state.sort.key,
        type:
          this.state.sort.direction === 'asc'
            ? new EnumType('ASC')
            : new EnumType('DESC'),
      },
    };

    this.props.listRequest({ args: omitBy(args, isNil) });
  };

  loadMore = () => {
    if (this.props.loading) {
      return;
    }

    this.setState({ offset: this.state.offset + DEFAULT_PAGE_SIZE }, () => this.getUserList());
  };

  onFilter = (data) => {
    this.setState({ isLoading: true }, () => {
      const { filterData, filteredColumns } = getFilterData(data);
      this.setState(
        {
          resetFilter: false,
          isLoading: false,
          filteredColumns,
          filter: filterData,
          offset: 0,
          limit: this.state.limit,
        },
        this.getUserList,
      );
    });
  };

  onReset = () => {
    const query = new URLSearchParams(this.props.router.location.search);

    const role = query.get('role');
    const { workspace } = this.props;

    if (this.state.filter || this.state.searchName) {
      this.setState(
        {
          filter: {
            single: [],
            range: [],
          },
          search: '',
          searchName: '',
          resetFilter: true,
          offset: 0,
          limit: this.state.limit,
        },
        this.getUserList,
      );
    }
    this.props.onReset();

    if (role) {
      return this.props.router.navigate(
        `/${workspace && slugify(workspace.name)}${
          routeMapping.SETTINGS_USER.path}`,
      );
    }
  };

  onRowClick = (id) => {
    const { workspace } = this.props;

    return this.props.router.navigate(
      `/${workspace && slugify(workspace.name)}${
        routeMapping.SETTINGS_USER.path
      }/${id}`,
    );
  };

  onInvite = () => {
    this.onCloseModal();
    this.props.onCreate();
  };

  onSearch = (data) => {
    const payload = {
      name: 'username',
      operator: new EnumType('contains'),
      value: data,
    };

    this.setState(
      {
        resetFilter: false,
        search: payload,
        searchName: data,
        offset: 0,
        limit: this.state.limit,
      },
      this.getUserList,
    );
  };

  onSkip = () => {
    this.props.updateStartedTrackerRequest({
      task: 'inviteFirstTeamMember',
      skipped: true,
    });
  }

  // pagination
  onChangeLimit = (offset) => {
    this.setState({
      currentPage: 1,
      limit: offset,
    }, () => {
      this.props.setCurrentPage && this.props.setCurrentPage(1, offset)
      this.getUserList();
    });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage }, () => {
      this.getUserList();
      this.props.setCurrentPage && this.props.setCurrentPage(currentPage)
    });
  };

  render() {
    const userList = this.props.userList
      && this.props.userList.data
      && this.props.userList.data.map(user => ({
        userId: user.internalId || user.emailAddress,
        ...user,
      }));

    const {
      activeView,
      selectedIds,
      onSelect,
      preventRedirectOnClick,
      color,
      detailRole,
      disabledIds,
      isAssignedPage,
      hasCreatePermission,
    } = this.props;

    let disabledIdsArray = disabledIds;

    const query = new URLSearchParams(this.props.router.location.search);
    const role = query.get('role');
    const roleId = query.get('roleId');

    const { resetFilter } = this.state;

    const noAccessUser = this.props.userList && this.props.userList.data
      .filter(item => !isRoleHigherThan(detailRole.role, item.role))
      .map(item => item.id);

    if (isAssignedPage) {
      disabledIdsArray = disabledIds && disabledIds.concat(noAccessUser);

      disabledIdsArray = disabledIdsArray && this.props.userList && this.props.userList.data
        .filter(item => disabledIdsArray.includes(item.id))
        .map(item => item.id);
    }

    if (this.props.disabledNonVisoUser) {
      disabledIdsArray = disabledIds && disabledIds.concat(noAccessUser);

      disabledIdsArray = disabledIdsArray && this.props.userList && this.props.userList.data
        .filter(item => !item.email.endsWith('@viso.ai'))
        .map(item => item.id);
    }

    return (
      <>
        {this.props.userList
        && this.props.tracker
        && this.props.tracker.inviteFirstTeamMember === 'open'
          && !this.state.searchName
          && !this.state.filter.single.length
          && !this.state.filter.range.length
          && !this.props.userList.isLoading
          && this.props.userList.data.length === 1
          && this.state.showModal
          && hasCreatePermission && (
          <SecondaryModal onClose={this.onCloseModal}>
            <NotFound
              color={color}
              strokeWidth="1"
              parentClass="not-found__popup"
              logo={<Users size="42" />}
              title={<FormattedMessage {...messages.inviteNewTeamMembers} />}
              description={
                <FormattedMessage {...messages.inviteOthersToJoin} />
              }
              buttonText={<FormattedMessage {...messages.invitePeople} />}
              onAction={this.onInvite}
            />
          </SecondaryModal>
        )}
        <div className="popup__filters-bar filters-bar">
          {/* {!preventRedirectOnClick && ( */}
          <ListSelectedItems
            selectedNumber={selectedIds.length}
            search={this.state.searchName}
            onReset={this.onReset}
            filterData={this.state.filter}
            containerClassName={color}
          />
          {/* )} */}
          <Filter
            defaultFilter={
              role
                ? [
                  {
                    field: 'status',
                    value: 'All',
                    isDefault: true,
                    columns: ['active', 'inactive', 'archived'],
                  },
                  {
                    field: 'role',
                    value: [role],
                    ids: [roleId],
                  },
                ]
                : [
                  {
                    field: 'status',
                    value: 'All',
                    isDefault: true,
                    columns: ['active', 'inactive', 'archived'],
                  },
                ]
            }
            customClass={color}
            resetFilter={resetFilter}
            onChange={this.onFilter}
            columns={filterColumns}
            data={this.props.userList && this.props.userList.data}
          >
            <FormattedMessage {...messages.search}>
              {msg => (
                <SearchField
                  onSearch={this.onSearch}
                  customClass={color}
                  resetSearch={this.state.resetFilter}
                  label={msg}
                />
              )}
            </FormattedMessage>
          </Filter>
        </div>


        {this.props.userList && this.props.userList.data && (
          <InfiniteScroll
            initialLoad={false}
            loader={(
              <div className="ml-3" key={0}>
                <FormattedMessage {...messages.loading} />
              </div>
            )}
            useWindow={false}
            getScrollParent={() => this.props.scrollRef}
          >
            {activeView !== CARD_VIEW  && (
              <>
                <UserTable
                  color={color}
                  multipleSelect
                  onDelete={this.props.onDelete}
                  isLoading={this.props.userList.isLoading}
                  filteredColumns={this.state.filteredColumns}
                  allowOnlySingleSelect={this.props.allowOnlySingleSelect}
                  onAssignRole={this.props.onAssignRole}
                  onUpdate={this.props.onUpdate}
                  disabledIds={disabledIdsArray}
                  onArchive={this.props.onArchive}
                  onDeactivate={this.props.onDeactivate}
                  onActivate={this.props.onActivate}
                  workspace={this.props.workspace}
                  loggedUserId={this.props.loggedUserId}
                  isAssignedPage={this.props.isAssignedPage}
                  preventRedirectOnClick={preventRedirectOnClick}
                  onRowClick={preventRedirectOnClick ? onSelect : this.onRowClick}
                  users={this.props.userList.data}
                  selectedItems={selectedIds}
                  hideSingleMenu={this.props.hideSingleMenu}
                  onSortChange={this.onSorting}
                  sort={this.state.sort}
                  onSelect={onSelect}
                  isSelectedAll={this.selectAll}
                />
              </>
            )}
            {activeView === CARD_VIEW && (
              <Card
                color={color}
                data={userList}
                multipleSelect
                onDelete={this.props.onDelete}
                isLoading={this.props.userList.isLoading}
                allowOnlySingleSelect={this.props.allowOnlySingleSelect}
                disabledIds={disabledIdsArray}
                workspace={this.props.workspace}
                loggedUserId={this.props.loggedUserId}
                hideSingleMenu={this.props.hideSingleMenu}
                onAssignRole={this.props.onAssignRole}
                onUpdate={this.props.onUpdate}
                isAssignedPage={this.props.isAssignedPage}
                onArchive={this.props.onArchive}
                onDeactivate={this.props.onDeactivate}
                onActivate={this.props.onActivate}
                selectedItems={selectedIds}
                onSelect={onSelect}
                preventRedirectOnClick={preventRedirectOnClick}
                onRowClick={preventRedirectOnClick ? onSelect : this.onRowClick}
                users={this.props.userList.data}
              />
            )}
            {(!!this.props.userList.count) && (
              <Pagination
                currentPage={this.state.currentPage}
                setCurrentPage={this.setCurrentPage}
                onChangeLimit={this.onChangeLimit}
                limit={this.state.limit}
                totalPage={this.props.userList.count
                }
              />
            )}
          </InfiniteScroll>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  userList: makeSelectFetchUser(),
  errors: makeSelectError(),
  detailRole: getDetailRole(),
  tracker: getTrackerInfo(),
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps,
  {
    listRequest: fetchUserRequest,
    updateStartedTrackerRequest,

  },
);

const withReducer = injectReducer({ key: 'user', reducer });
const withSaga = injectSaga({ key: 'user', saga });
const withGroupReducer = injectReducer({
  key: 'userGroup',
  reducer: groupReducer,
});
const withGroupSaga = injectSaga({
  key: 'userGroup',
  saga: groupSaga,
});
const withRoleReducer = injectReducer({
  key: 'role',
  reducer: roleReducer,
});
const withRoleSaga = injectSaga({
  key: 'role',
  saga: roleSaga,
});

export default compose(
  withReducer,
  withGroupReducer,
  withRoleReducer,
  withRoleSaga,
  withSaga,
  withRouter,
  withGroupSaga,
  withConnect,
)(UserList);
