import {orderBy} from 'lodash'
import Filter from 'components/Filter';
import { getFilterData } from 'components/Filter/filter';
import ListSelectedItems from 'components/ListSelectedItems';
import ProcessPage from 'components/ProcessPage';
import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux'

import { DEFAULT_PAGE_SIZE, SOLUTION_ACCENT, CARD_VIEW, TABLE_VIEW  } from 'utils/constants';
import messages from 'utils/messages';
import filterColumns from './filter';
import UserTable from './Table';
import Card from './Card';
import Pagination from 'components/Pagination'
import { getOutput } from 'components/Filter/clientFilter';

import { isSmallerDevice } from 'utils/common';
import { makeSelectDevice } from 'containers/App/selectors';
import HistoryIndividual from '../../details/HistoryInvoice'

class BillingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: {
        key: 'invoiceNo',
        direction: 'desc'
      },
      activeView: TABLE_VIEW,
      filter: {
        single: [],
        range: []
      },
      resetFilter: false,
      resetSearch: false,
      offset: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      selectedId: null,
      showHistoryDetailPage: ''
    };
  }

  onSorting = (sort) => {
    const hasFilter = !!this.state.filter.range.length
      || !!this.state.filter.single.length
      || this.state.searchName;

    this.setState({
      sort,
      isSorted: true,
      filteredValue: orderBy(
        hasFilter ? this.state.filteredValue : this.props.invoices,
        [sort.key || sort.key.name],
        [sort.direction],
      ),
    });
  };

  onRowClick=(id) => {
    this.setState({selectedId: id, showHistoryDetailPage: true});
  }

  /* Filter End*/
  componentDidMount() {
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (isSmallerDeviceType) {
      return this.setState({ activeView: CARD_VIEW });
    }
  }

  componentWillReceiveProps(nextProps) {
    const isNextSmallerDeviceType = isSmallerDevice(nextProps.deviceSizeType);
    const isSmallerDeviceType = isSmallerDevice(this.props.deviceSizeType);

    if (
      !isSmallerDeviceType &&
      isNextSmallerDeviceType &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.setState({ activeView: CARD_VIEW });
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === 'desktop'
    ) {
      return this.setState({ activeView: TABLE_VIEW });
    }
  }

  // pagination
  onChangeLimit = (offset) => {
    this.setState({ offset: offset });
    this.setState({ currentPage: 1 });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage });
  };

  onFilter = data => {
    const { filterData, filteredColumns } = getFilterData(data);

    const finalResult = getOutput(filterData, this.props.invoices);
    let result = finalResult;
    this.setCurrentPage(1);

    if (this.state.searchName) {
      result = finalResult.filter(item =>
        item.name.toLowerCase().includes(this.state.searchName.toLowerCase())
      );
    }

    this.setState({
      isLoading: false,
      filter: filterData,
      resetFilter: false,
      filteredColumns: filteredColumns,
      filteredValue: result
    });
  };

  onReset = () => {
    this.setState({ isAllSelected: false });
    if (this.state.filter || this.state.searchName) {
      this.setState(
        {
          filter: {
            single: [],
            range: []
          },
          search: '',
          searchName: '',
          resetFilter: true,
          offset: 0
        },
        this.getInvoices
      );
    }

    this.props.onReset();
  };

  onReset = () => {
    this.setState({ selectedIds: [] });
  };

  state = {
    selectedIds: [],
    selectItemFromTable: true
  };

  onSelect = (id, e) => {
    e.stopPropagation();
    this.setState({ selectedIds: id });
  };

  onSetSelectedIds = devices => {
    this.setState({ selectedIds: devices, selectItemFromTable: false });
  };

  render() {
    const { color, workspace, invoices, billing } = this.props;
    const { resetFilter, filter, selectedId, currentPage } = this.state;

    const hasFilter =
    this.state.isSorted ||
    !!this.state.filter.range.length ||
    !!this.state.filter.single.length ||
    this.state.searchName;

    if (this.state.showHistoryDetailPage) {
      const invoice = invoices && invoices.find(inv => inv.invoiceId === selectedId);

      return (
        <HistoryIndividual
          workspace={workspace}
          plans={this.props.plans}
          billing={billing}
          onClose={() => this.setState({showHistoryDetailPage: false})}
          upcomingInvoice={invoice}
        />
      )
    }

    return (
      <ProcessPage onCancel={this.props.onClose}>
        {(scrollRef) => (
          <>
            <div className="headline">
              <h1 className="headline__title">
                Billing History
              </h1>
              <h3 className="headline__subtitle">{this.props.workspaceName}</h3>
            </div>

            <div className="filters-bar">
              <ListSelectedItems
                selectedNumber={0}
                onReset={this.onReset}
                filterData={!!(filter && filter.length)}
                containerClassName={color}
              />
              <Filter
                defaultFilter={
                  [
                    {
                      field: 'status',
                      isDefault: true,
                      value: 'All',
                      columns: ['paid', 'open', 'draft', 'uncollectible', 'void', 'overdue']
                    }
                  ]
                }
                customClass={color}
                resetFilter={resetFilter}
                onChange={this.onFilter}
                columns={filterColumns}
                data={this.props.invoices}
              >
              </Filter>
            </div>
            <InfiniteScroll
              initialLoad={false}
              loader={
                <div className="ml-3" key={0}>
                  <FormattedMessage {...messages.loading} />
                </div>
              }
              useWindow={false}
              getScrollParent={() => scrollRef}
            >
              {this.props.invoices && this.state.activeView === TABLE_VIEW && (
                <UserTable
                  color={color}
                  onRowClick={this.onRowClick}
                  users={
                    hasFilter
                      ? this.state.filteredValue.filter(
                        (item, index) => {
                          if (
                            currentPage === 1 &&
                            index < this.state.offset
                          ) {
                            return item;
                          }

                          if (
                            currentPage !== 1 &&
                            index <
                              this.state.offset * currentPage &&
                            index >=
                              this.state.offset * (currentPage - 1)
                          ) {
                            return item;
                          }

                          return false;
                        }
                      )
                      : this.props.invoices.filter(
                        (item, index) => {
                          if (
                            currentPage === 1 &&
                            index < this.state.offset
                          ) {
                            return item;
                          }

                          if (
                            currentPage !== 1 &&
                            index <
                              this.state.offset * currentPage &&
                            index >=
                              this.state.offset * (currentPage - 1)
                          ) {
                            return item;
                          }

                          return false;
                        }
                      )
                  }
                  isLoading={this.props.invoices.loading}
                  onSortChange={this.onSorting}
                  sort={this.state.sort}
                  onSelect={this.onSelect}
                  isSelectedAll={this.selectAll}
                />
              )}
              {this.props.invoices && this.state.activeView === CARD_VIEW && (
                <Card
                  color={color}
                  onRowClick={this.onRowClick}
                  data={this.props.invoices}
                  isLoading={this.props.invoices.loading}
                  onSortChange={this.onSorting}
                  sort={this.state.sort}
                  onSelect={this.onSelect}
                  isSelectedAll={this.selectAll}
                />
              )}
              {!!this.props.invoices && (((hasFilter && !!this.state.filteredValue.length) ||
              (!hasFilter && !!this.props.invoices.length))) && (
                <Pagination
                  currentPage={this.state.currentPage}
                  setCurrentPage={this.setCurrentPage}
                  onChangeLimit={this.onChangeLimit}
                  limit={this.state.offset}
                  totalPage={
                    hasFilter
                      ? this.state.filteredValue.length
                      : this.props.invoices.length
                  }
                />
              )}
            </InfiniteScroll>
          </>
        )}
      </ProcessPage>
    );
  }
}

BillingList.propTypes = {
  color: PropTypes.string
};

BillingList.defaultProps = {
  color: SOLUTION_ACCENT
};

const mapStateToProps = createStructuredSelector({
  deviceSizeType: makeSelectDevice(),
});

const withConnect = connect(
  mapStateToProps
);

export default withConnect(BillingList);
