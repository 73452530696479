const zoho = (callback) => {
  const existingScript = document.getElementById('zoho');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://desk.zoho.eu/portal/api/web/inapp/47008000000481031?orgId=20072449357';
    script.nonce = 'viso';
    script.id = 'zoho';
    script.className='zoho-class';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export default zoho;
