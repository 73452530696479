import {
  takeLatest,
  call,
  put,
  all,
  select,
  delay
} from 'redux-saga/effects'
import {
  getModule,
  syncNodes,
  createFlow,
  updateApplication,
  createPublicDockerImage,
  getFlowVersion,
  getModuleVersion,
  getLibraryModules,
  getNoderedModules,
  getNoderedModule,
  getAwsAccessKey,
  getLibraryApplications,
  getDockerImages,
  getApplication,
  getCommitList,
  pushChanges,
  importVideo,
  videoDetail,
  createPrivateDockerImage,
  getVideos,
  updateVideo,
  getLibraryModels,
  uploadModelFile,
  getLibraryModelId,
  getLibraryEnvironments,
  getModelTraining,
  getExperiments,
  getJobTrainingList,
  getAnnotationTask,
  getRelatedModels,
  getJobs,
  getJobTrainingDetails
} from 'api/library';
import { getDatasets, getDatasetDetail, getSamplesData, getSamples } from 'api/dataset';
import { getSSHKeyList, installDependency } from 'api/utility';
import { intl } from 'containers/LanguageProvider/intlProvider';
import messages from 'utils/messages';
import {
  updateModule,
  getFileData,
  upgradePublicModule,
  uploadZipFile,
  uploadApplicationZipFile,
  createApplicationFromTemplate,
  fetchTemplateList,
  initMultipartUpload,
  finalizeMultipartUpload,
  getFileAwsData
} from 'api/solution';
import {
  updateNotifier,
  defaultNotifier,
  successNotifier,
} from 'functions/notificationHandler';
import { routeMapping } from 'routes';
import { sortBy } from 'lodash';
import {
  getModuleError,
  getModuleSuccess,
  libraryFlowsListError,
  libraryFlowsListSuccess,
  libraryModulesListError,
  libraryModulesListSuccess,
  getSSHKeySuccess,
  getSSHKeyError,
  getFlowDetailError,
  getFlowDetailSuccess,
  createFlowSuccess,
  createFlowError,
  getCommitsSuccess,
  getModuleVersionSuccess,
  getDockerImagesSuccess,
  getNodeRedModulesSuccess,
  getNodeRedModuleSuccess,
  uploadModuleFileSuccess,
  getAwsAccessKeySuccess,
  getTemplateListSuccess,
  getTemplateListError,
  uploadModuleFileError,
  addApplicationFromTemplateSuccess,
  addApplicationFromTemplateError,
  addVideoFileSuccess,
  listVideosSuccess,
  createPrivateDockerImageSuccess,
  listVideoSuccess,
  updateFlowSuccess,
  libraryModelListSuccess,
  libraryModelSuccess,
  libraryImageAnnotationListSuccess,
  libraryModelTrainingListSuccess,
  getDatasetListSuccess,
  getDatasetDetailSuccess,
  getExperimentsSuccess,
  addFilesSuccess,
  libraryJobTrainingListSuccess,
  libraryJobTrainingDetailsSuccess,
  libraryRelatedModelListSuccess,
  libraryAnnotationTaskSuccess,
  libraryAnnotationJobSuccess
} from './actions';
import { uploadTracker } from '../../containers/App/actions'
import {
  GET_SSH_KEY_REQUEST,
  SYNC_NODE_REQUEST,
  UPLOAD_FILE_REQUEST,
  GET_MODULE_REQUEST,
  UPDATE_MODULE_REQUEST,
  GET_APPLICATION_DETAIL_REQUEST,
  GET_FLOW_VERSION_REQUEST,
  CREATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_REQUEST,
  NODE_RED_MODULE_REQUEST,
  LIST_LIBRARY_APPLICATIONS_REQUEST,
  LIST_LIBRARY_MODULES_REQUEST,
  GET_MODULE_VERSION_REQUEST,
  LIST_COMMITS_REQUEST,
  CREATE_PUBLIC_IMAGE_DOCKER_REQUEST,
  CREATE_PRIVATE_IMAGE_DOCKER_REQUEST,
  GET_DOCKER_IMAGES_REQUEST,
  NODE_RED_MODULES_REQUEST,
  PUSH_CHANGES_REQUEST,
  GET_AWS_ACCESS_KEY_REQUEST,
  INSTALL_DEPENDENCIES_REQUEST,
  UPGRADE_PUBLIC_MODULE,
  LIST_TEMPLATE_REQUEST,
  IMPORT_FILE_APPLICATION_REQUEST,
  ADD_APPLICATION_FROM_TEMPLATE_REQUEST,
  ADD_VIDEO_REQUEST,
  ADD_VIDEO_FILE_REQUEST,
  LIST_VIDEOS_REQUEST,
  LIST_VIDEO_REQUEST,
  UPDATE_VIDEO_REQUEST,
  LIST_LIBRARY_MODELS_REQUEST,
  LIST_LIBRARY_RELATED_MODELS_REQUEST,
  ADD_MODEL_FILE_REQUEST,
  LIST_MODEL_REQUEST,
  GET_EXPERIMENTS_REQUEST,
  LIST_ENVIRONMENT_REQUEST,
  LIST_LIBRARY_MODEL_TRAINING_REQUEST,
  LIST_LIBRARY_JOB_TRAINING_REQUEST,
  DETAILS_LIBRARY_JOB_TRAINING_REQUEST,
  LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST,
  LIST_DATASET_REQUEST,
  DETAIL_DATASET_REQUEST,
  UPLOAD_FILES_REQUEST,
  LIST_LIBRARY_ANNOTATION_TASK_REQUEST,
  LIST_LIBRARY_ANNOTATION_TASK_SUCCESS,
  LIST_LIBRARY_ANNOTATION_TASK_ERROR,
  LIST_LIBRARY_ANNOTATION_JOB_REQUEST,
  LIST_LIBRARY_ANNOTATION_JOB_SUCCESS,
  LIST_LIBRARY_ANNOTATION_JOB_ERROR,
} from './constants';
import { v4 as uuidv4 } from 'uuid';

export const getUploadTracker = (state) => state.global

let chunksQueue = [];
let payload = [];
let sentSize = [];
let chunkId = 0;
let chunk = null;
let chunkSize = 52428800;
let file = null;
let aborted = false;
let uploadedSize = 0;
let progressCache = {};
let activeConnections = {};

function* libraryApplicationListSaga(action) {
  try {
    const response = yield call(getLibraryApplications, action.payload)

    if (response.data.errors) {
      yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }
    const list = sortBy(response.data.data.applications, ['createdAt']).reverse();

    yield put(libraryFlowsListSuccess(list))
  } catch (err) {
    defaultNotifier(err)
    yield put(libraryFlowsListError(err))
  }
}

function* libraryModelListSaga(action) {
  try {
    const response = yield call(getLibraryModels, action.payload)

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }
    const list = sortBy(response.data.data.models, ['createdAt']).reverse();

    yield put(libraryModelListSuccess(list))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

function* libraryRelatedModelListSaga(action) {
  try {
    const response = yield call(getRelatedModels, action.payload)

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }
    const list = sortBy(response.data.data.relatedModels, ['createdAt']).reverse();

    yield put(libraryRelatedModelListSuccess(list))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

function* libraryExperimentListSaga(action) {
  try {
    const response = yield call(getExperiments, action.payload)

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }
    const list = sortBy(response.data.data.experiments.data, ['createdAt']).reverse();

    yield put(getExperimentsSuccess({
      count: response.data.data.experiments.count,
      data: list
    }))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

function* libraryAnnotationJobListSaga(action) {
  try {
    const response = yield call(getJobs, action.payload);

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }
    const list = sortBy(response.data.data.cvatJobs.data, ['createdAt']).reverse();

    yield put(libraryAnnotationJobSuccess({
      count: response.data.data.cvatJobs.count,
      data: list
    }))

    if(action.meta) {
      action.meta()
    }
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}


function* libraryImageAnnotationListSaga(action) {
  try {
    const response = yield call(getLibraryEnvironments, action.payload);

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors);
    }

    const list = response.data.data.annotationEnvs;

    yield put(libraryImageAnnotationListSuccess(list));
  } catch (err) {
    defaultNotifier(err);
    // yield put(libraryModelListError(err))
  }
}

function* libraryModelTrainingListSaga(action) {
  try {
    const response = yield call(getModelTraining, action.payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    }

    const list = response.data.data.trainingEnvs;

    yield put(libraryModelTrainingListSuccess(list));
  } catch (err) {
    defaultNotifier(err);
    // yield put(libraryModelListError(err))
  }
}

function* libraryJobTrainingListSaga(action) {
  try {
    const response = yield call(getJobTrainingList, action.payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    }

    const list = sortBy(response.data.data.trainingJobs.data, ['createdAt']).reverse();

    yield put(libraryJobTrainingListSuccess({
      count: response.data.data.trainingJobs.count,
      data: list
    }))
  } catch (err) {
    defaultNotifier(err);
    // yield put(libraryModelListError(err))
  }
}

function* libraryAnnotationTaskListSaga(action) {
  try {
    const response = yield call(getAnnotationTask, action.payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    }

    const list = sortBy(response.data.data.cvatTasks.data, ['createdAt']).reverse();

    yield put(libraryAnnotationTaskSuccess({
      count: response.data.data.cvatTasks.count,
      data: list
    }))
  } catch (err) {
    defaultNotifier(err);
    // yield put(libraryModelListError(err))
  }
}

function* libraryJobTrainingDetailsSaga(action) {
  try {
    const response = yield call(getJobTrainingDetails, action.payload);

    if (response.data.errors) {
      defaultNotifier(response.data.errors);
    }

    const list = response.data.data.trainingJob

    yield put(libraryJobTrainingDetailsSuccess(list))
  } catch (err) {
    defaultNotifier(err);
    // yield put(libraryModelListError(err))
  }
}


function* libraryModelSaga(action) {
  try {
    const response = yield call(getLibraryModelId, action.payload)

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }

    yield put(libraryModelSuccess(response.data.data.model))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

function* libraryEnvironmentSaga(action) {
  try {
    const response = yield call(getLibraryModelId, action.payload)

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }

    yield put(libraryModelSuccess(response.data.data.model))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

function* listTemplateSaga(action) {
  try {
    const response = yield call(fetchTemplateList, action.payload)

    if (response.data.errors) {
      yield put(getTemplateListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }

    const list = sortBy(response.data.data.templates, ['createdAt']).reverse();

    yield put(getTemplateListSuccess(list))
  } catch (err) {
    defaultNotifier(err)
    yield put(getTemplateListError(err))
  }
}

function* listVideosSaga(action) {
  try {
    const response = yield call(getVideos, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    }

    const list = sortBy(response.data.data.videos, ['createdAt']).reverse();

    yield put(listVideosSuccess(list))
  } catch (err) {
    defaultNotifier(err)
  }
}

function* libraryModulesListSaga(action) {
  try {
    const response = yield call(getLibraryModules, action.payload)

    if (response.data.errors) {
      yield put(libraryModulesListError(response.data.errors))
      defaultNotifier(response.data.errors)
    } else {
      const list = sortBy(response.data.data.modules, ['createdAt']).reverse();

      yield put(libraryModulesListSuccess(list))
    }
  } catch (err) {
    defaultNotifier(err)
    yield put(libraryModulesListError(err))
  }
}

function* getModuleSaga(action) {
  try {
    const response = yield call(getModule, action.payload)
    if (response.data.errors) {
      yield put(getModuleError(response.data.errors))
      defaultNotifier(response.data.errors)
    } else {
      yield put(getModuleSuccess(response.data.data.moduleDetail))

      if (action.meta) {
        action.meta(response.data.data.moduleDetail.moduleID)
      }
    }
  } catch (err) {
    yield put(getModuleError(err))
    defaultNotifier(err)
  }
}

function* getModuleVersionSaga(action) {
  try {
    const response = yield call(getModuleVersion, action.payload)
    if (response.data.errors) {
      yield put(getModuleError(response.data.errors))
      defaultNotifier(response.data.errors)
    } else {
      yield put(getModuleVersionSuccess(response.data.data.moduleHistories))
    }
  } catch (err) {
    yield put(getModuleError(err))
    defaultNotifier(err)
  }
}

function* updateModuleSaga(action) {
  try {
    const response = yield call(updateModule, action.payload)
    if (response.data && response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {

      if (action.meta.onCallback) {
        action.meta.onCallback()
      }

      defaultNotifier(intl.formatMessage({ ...messages.moduleChangesSaved }))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* getSSHKey(action) {
  try {
    const response = yield call(getSSHKeyList, action.payload)

    if (response.data.errors) {
      yield put(getSSHKeyError(response.data.errors))
      defaultNotifier(intl.formatMessage({ ...messages.failedToFetchTHeSSHKeys }))
    } else {
      yield put(getSSHKeySuccess(response.data.data.keys))
    }
  } catch (err) {
    yield put(getSSHKeyError(err))
    defaultNotifier(intl.formatMessage({ ...messages.failedToFetchTHeSSHKeys }))
  }
}

function* getApplicationDetailSaga(action) {
  try {
    const response = yield call(getApplication, action.payload)

    if (response.data.errors) {
      yield put(getFlowDetailError(response.data.errors))
      defaultNotifier(response.data.errors)
    } else {
      yield put(getFlowDetailSuccess(response.data.data.application))
    }
  } catch (err) {
    yield put(getFlowDetailError(err))
    defaultNotifier(err)
  }
}

function* getFlowVersionSaga(action) {
  try {
    const response = yield call(getFlowVersion, action.payload)

    // if (response.data.errors) {
    //   yield put(getFlowDetailError(response.data.errors))
    // defaultNotifier(response.data.errors)
    // } else {
    yield put(getFlowDetailSuccess({
      ...response.data.data.flowHistories.data[0],
      id: action.payload.applicationId
    }))
    // }
  } catch (err) {
    yield put(getFlowDetailError(err))
    defaultNotifier(err)
  }
}


function* createFlowSaga(action) {
  try {
    const response = yield call(createFlow, action.payload)
    if (response.data.errors) {
      yield put(createFlowError(response.data.errors))
      defaultNotifier(response.data.errors)
    } else {
      yield put(createFlowSuccess(response.data.data.createFlow))
      action.meta.onCallback(response.data.data.createFlow.id)
    }
  } catch (err) {
    yield put(createFlowError(err))
    defaultNotifier(err)
  }
}

function* updateApplicationSaga(action) {
  try {
    const response = yield call(updateApplication, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
      defaultNotifier(response.data.errors)
    } else {
      if (response.data.errors) {
        return defaultNotifier(response.data.errors)
      }
      yield put(updateFlowSuccess())

      action.meta(`${routeMapping.LIBRARY_APPLICATIONS.path}`);
      return defaultNotifier(intl.formatMessage({ ...messages.applicationChangesSaved }))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* getCommits(action) {
  try {
    const response = yield call(getCommitList, action.payload)

    if (response.data.errors) {
      // yield put(getCommitsError(response.data.errors))
      defaultNotifier(response.data.errors)
    } else {
      yield put(getCommitsSuccess(response.data.data.applicationCommits))
    }
  } catch (err) {
    // yield put(getCommitsError(err))
    defaultNotifier(err)
  }
}

function* getNodeRedModuleList(action) {
  try {
    const response = yield call(getNoderedModules, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      yield put(getNodeRedModulesSuccess(response.data.data.noderedModules))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* getNodeRedModule(action) {
  try {
    const response = yield call(getNoderedModule, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      yield put(getNodeRedModuleSuccess(response.data.data.noderedModule))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* syncNodesSaga(action) {
  try {
    const response = yield call(syncNodes, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      yield put(getNodeRedModulesSuccess(response.data.data.noderedModules))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* dockerImageSaga(action) {
  try {
    const response = yield call(getDockerImages, action.payload)
    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      yield put(getDockerImagesSuccess(response.data.data.dockerImages))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* createPublicDockerSaga(action) {
  try {
    const response = yield call(createPublicDockerImage, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      // yield put(createPublicDockerImageSuccess(response.data.data.noderedModules))
      defaultNotifier(intl.formatMessage({ ...messages.successfullyCreatedAPublicContainer }))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* createPrivateDockerSaga(action) {
  try {
    const response = yield call(createPrivateDockerImage, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      yield put(createPrivateDockerImageSuccess(response.data.data.createPrivateDockerImage))
      defaultNotifier(intl.formatMessage({ ...messages.successfullyCreatedAPrivateDockerContainer }))
      action.meta.onCallback()
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* pushChangesSaga(action) {
  try {

    const response = yield call(pushChanges, action.payload)

    if (response.data && response.data.errors) {
      defaultNotifier(response.data.errors)
    } else if (action.meta) {
      action.meta()
      defaultNotifier(intl.formatMessage({ ...messages.commitPushedSuccessfully }))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* awsAccessKeySaga(action) {
  try {
    const response = yield call(getAwsAccessKey)

    if (response.data && response.data.errors) {
      defaultNotifier(response.data.errors)
    }
    action.payload.onCallback()
    yield put(getAwsAccessKeySuccess(response.data.data.awsAccessKey))
  } catch (err) {
    defaultNotifier(err)
  }
}

function* installDependencySaga(action) {
  try {
    const response = yield call(installDependency, action.payload)

    if (response.data && response.data.errors) {
      defaultNotifier(response.data.errors)
    }

  } catch (err) {
    defaultNotifier(err)
  }
}

function* moduleFileUploadSaga(action) {
  try {
    const response = yield call(uploadZipFile, action.payload)

    if (response.data && response.data.errors) {
      defaultNotifier(response.data.errors)
    }

    yield call(
      getFileData, { response: response.data.data.uploadCustomModule, file: action.payload.file }, action.meta)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    }

    yield put(uploadModuleFileSuccess(response.data.data.uploadCustomModule.fields.key))

    if(action.onCallback) {
      action.onCallback()
    }
  } catch (err) {
    defaultNotifier(err)
  }
}


function* applicationFileUploadSaga(action) {
  try {
    const response = yield call(uploadApplicationZipFile, action.payload)

    if (response.data && response.data.errors) {
      yield put(uploadModuleFileError())
      defaultNotifier(response.data.errors)
    }

    yield call(
      getFileData, { response: response.data.data.uploadFile, file: action.payload.file }, action.meta)

    yield put(uploadModuleFileSuccess(response.data.data.uploadFile.fields.key));
    // defaultNotifier(intl.formatMessage({...messages.applicationImported}))
  } catch (err) {
    yield put(uploadModuleFileError())
    defaultNotifier(err)
  }
}

const upload = function (file, id) {
  return new Promise((resolve, reject) => {
    const xhr = this.activeConnections[id] = new XMLHttpRequest();
    const progressListener = this.handleProgress.bind(this, id);

    xhr.upload.addEventListener("progress", progressListener);

    xhr.addEventListener("error", progressListener);
    xhr.addEventListener("abort", progressListener);
    xhr.addEventListener("loadend", progressListener);

    xhr.open("post", "/upload");

    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.setRequestHeader("Content-Length", file.size);
    xhr.setRequestHeader("X-Content-Id", this.fileId);
    xhr.setRequestHeader("X-Chunk-Id", id);

    xhr.onreadystatechange = (event) => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.responseText);
        delete this.activeConnections[id];
      }
    };

    xhr.onerror = (error) => {
      reject(error);
      delete this.activeConnections[id];
    };

    xhr.onabort = () => {
      reject(new Error("Upload canceled by user"));
      delete this.activeConnections[id];
    };

    xhr.send(file);
  })
}

function* addVideoFileSaga(action) {
  try {
    const payload = {
      filename: action.payload.file.name,
      parts: action.payload.parts
    }

    const response = yield call(initMultipartUpload, payload)

    if (response.data && response.data.errors) {
      return defaultNotifier(response.data.errors)
    }

    chunksQueue = new Array(action.payload.parts).fill().map((_, index) => index).reverse();

    const result = yield all(chunksQueue.map(function (item, index) {
      sentSize = index * chunkSize;
      chunk = action.payload.file.slice(sentSize, sentSize + chunkSize)

      return call(
        getFileAwsData, {
        response:
        {
          url: response.data.data.initMultipartUpload.signedUrls[index],
          uploadId: response.data.data.initMultipartUpload.uploadId,
          index: chunkId
        },
        file: chunk
      }, action.meta)
    }))

    const parts = result.map((item, index) => {
      const query = new URLSearchParams(item.config.url);
      const partNumber = query.get('partNumber');

      return {
        ETag: item.headers.etag,
        PartNumber: parseInt(partNumber)
      }
    })

    const result2 = yield call(
      finalizeMultipartUpload, {
      s3Key: response.data.data.initMultipartUpload.s3Key,
      uploadId: response.data.data.initMultipartUpload.uploadId,
      parts: parts
    }, action.meta)

    if (result2.data && result2.data.errors) {
      return defaultNotifier(result2.data.errors)
    }

    yield put(addVideoFileSuccess(response.data.data.initMultipartUpload.s3Key));
    defaultNotifier(intl.formatMessage({ ...messages.videoUploadedSuccessfully }))

    // const response = yield call(uploadApplicationZipFile, action.payload)

    // if (response.data && response.data.errors) {
    //   defaultNotifier(response.data.errors)
    // }

    // yield call(
    //   getFileData, { response: response.data.data.uploadFile, file: action.payload.file }, action.meta)

    // defaultNotifier(intl.formatMessage({ ...messages.videoImported }))

  } catch (err) {
    yield put(uploadModuleFileError())
    defaultNotifier(err)
  }
}

function* upgradePublicModuleSaga(action) {
  try {
    const response = yield call(upgradePublicModule, action.payload)
    if (response.data && response.data.errors) {
      defaultNotifier(response.data.errors)
    } else {
      yield put(getModuleSuccess(response.data.data.upgradePublicModule))

      try {
        const responseVersion = yield call(getModuleVersion, { moduleId: response.data.data.upgradePublicModule.id })

        if (responseVersion.data.errors) {
          yield put(getModuleError(responseVersion.data.errors))
          defaultNotifier(responseVersion.data.errors)
        } else {
          yield put(getModuleVersionSuccess({
            ...responseVersion.data.data.moduleHistories
          }))
        }
        defaultNotifier(intl.formatMessage({ ...messages.updatedSuccessfully }))
      } catch (err) {
        yield put(getModuleError(err))
        defaultNotifier(err)
      }

      if (action.meta) {
        action.meta.onCallback()
      }
      updateNotifier(intl.formatMessage({ ...messages.moduleChangesSaved }))
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* addApplicationFromTemplateSaga(action) {
  try {
    const response = yield call(createApplicationFromTemplate, action.payload)

    if (response.data && response.data.errors) {
      yield put(addApplicationFromTemplateError())
      return defaultNotifier(response.data.errors)
    }

    yield put(addApplicationFromTemplateSuccess(response.data.data.createApplicationFromTemplate))

    successNotifier(intl.formatMessage({ ...messages.createApplicationSuccessful }),
      action.meta(response.data.data.createApplicationFromTemplate.id))
  } catch (err) {
    defaultNotifier(err)
  }
}

function* addVideoSaga(action) {
  try {
    const response = yield call(importVideo, action.payload)

    if (response.data && response.data.errors) {
      return defaultNotifier(response.data.errors)
    }
    successNotifier(intl.formatMessage({ ...messages.newVideoCreated }), action.meta(response.data.data.importVideo.id))
  } catch (err) {
    defaultNotifier(err)
  }
}

function* updateVideoSaga(action) {
  try {
    const response = yield call(updateVideo, action.payload)

    if (response.data && response.data.errors) {
      return defaultNotifier(response.data.errors)
    }
    successNotifier(intl.formatMessage({ ...messages.videoUpdated }))

    if (action.meta) {
      action.meta();
    }
  } catch (err) {
    defaultNotifier(err)
  }
}

function* listVideoSaga(action) {
  try {
    const response = yield call(videoDetail, action.payload)

    if (response.data.errors) {
      defaultNotifier(response.data.errors)
    }

    yield put(listVideoSuccess(response.data.data.video))
  } catch (err) {
    defaultNotifier(err)
  }
}

function* modelFileUploadSaga(action) {
  try {
    const response = yield call(uploadModelFile, action.payload, action.meta)

    if (response.data && response.data.errors) {
      // yield put(uploadModuleFileError())
      defaultNotifier(response.data.errors)
    }

    yield call(
      getFileData, { response: response.data.data.uploadFile, file: action.payload.file }, action.meta)

    yield put(uploadModuleFileSuccess(response.data.data.uploadFile.fields.key));
    // defaultNotifier(intl.formatMessage({...messages.applicationImported}))
  } catch (err) {
    yield put(uploadModuleFileError())
    defaultNotifier(err)
  }
}

function* libraryDatasetSaga(action) {
  try {
    const response = yield call(getDatasets, action.payload)
    let data = []
    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }

    const results = sortBy(response.data.data.datasets, ['createdAt']).reverse();

    // for (const result in results) {
    //   const sample = yield call(
    //     getSamplesData, { datasetId:  results[result].id })

    //   if(sample.data.errors) {
    //     return data.push({...result})
    //   }
    //   data.push({...results[result], count: sample.data.data.getSampleData.count})
    // }

    yield put(getDatasetListSuccess(results))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

function* libraryDatasetDetailSaga(action) {
  try {
    const response = yield call(getDatasetDetail, action.payload)

    if (response.data.errors) {
      // yield put(libraryFlowsListError(response.data.errors))
      defaultNotifier(response.data.errors)
    }

    const result = response.data.data.dataset

    const detailDatas = {
      ...result,
      samples: [],
      isSampleLoading: true
    }

    yield put(getDatasetDetailSuccess(detailDatas))

    if(action.meta) {
      action.meta()
    }

    const responseSample = yield call(getSamples, { datasetId: result.id })

    if (responseSample.data.errors) {
      const detailDataset = {
        ...result,
        samples: [],
        isSampleLoading: false

      }

      yield put(getDatasetDetailSuccess(detailDataset))
      // yield put(getDatasetDetailSuccess(responseSample))
    }

    const detailDataset = {
      ...result,
      isSampleLoading: false,
      samples: responseSample.data.data.samples
    }

    yield put(getDatasetDetailSuccess(detailDataset))
  } catch (err) {
    defaultNotifier(err)
    // yield put(libraryModelListError(err))
  }
}

const startTime = Date.now();

function onUploadProgress1(progressEvent, fileIndex, onUpdate, totalSize, action) {
  const percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );

  // Calculate the current uploaded size
  const currentFileUploadedSize = progressEvent.loaded;
  const totalUploadedSize = payload.reduce((acc, item, index) => {
    return acc + (index === fileIndex ? currentFileUploadedSize : item.uploadedBytes || 0);
  }, 0);

  const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
  const uploadSpeed = totalUploadedSize / elapsedTime; // bytes per second
  const remainingBytes = totalSize - totalUploadedSize;
  const estimatedTime = remainingBytes / uploadSpeed; // in seconds

  const minutes = Math.ceil(estimatedTime / 60) || '00';
  const seconds = Math.ceil(estimatedTime % 60) || '00';

  payload = payload.map((item, index) => {
    if (index === fileIndex) {
      return {
        ...item,
        progress: percentCompleted,
        time: `${minutes || '00'}m ${seconds || '00'}s`,
        remainingBytes: remainingBytes,
        uploadedBytes: currentFileUploadedSize
      }
    }
    return {
      ...item,
      time: `${minutes}m ${seconds}s`,
      remainingBytes: remainingBytes
    }
  })

  onUpdate(payload)
}

function* filesUploadSaga(action) {
  try {
    payload = action.payload.files.map((item, index) => ({
      item,
      index: index,
      progress: 0
    }))

    let start = 0;
    let end = 3;
    const dirName = uuidv4();
    yield put(uploadTracker(payload))

    let totalSize = action.payload.files.reduce((sum, file) => sum + file.size, 0);

    while (start < action.payload.files.length) {
      const results = yield all(action.payload.files
        .filter((item, index) => index >= start && index < end)
        .map(file =>
          call(uploadModelFile, { file, dirName: dirName }, action.meta)
        )
      );

      yield all(results.map(function (result, index) {
        return call(
          getFileData, { response: result.data.data.uploadFile, file: action.payload.files[index + start] }, {
          onUploadProgress: (data) => onUploadProgress1(data, index + start, action.update, totalSize, action)
        })
      }))
      end += 3;
      start += 3;
      yield delay(5000);
    }
    action.meta([], dirName)
    yield put(addFilesSuccess());


    yield put(uploadTracker([]))
  } catch (err) {
    yield put(uploadModuleFileError())
    defaultNotifier(err)
  }
}

export default function* libraryRootSaga() {
  yield takeLatest(UPLOAD_FILES_REQUEST, filesUploadSaga);
  yield takeLatest(PUSH_CHANGES_REQUEST, pushChangesSaga);
  yield takeLatest(UPGRADE_PUBLIC_MODULE, upgradePublicModuleSaga);
  yield takeLatest(UPLOAD_FILE_REQUEST, moduleFileUploadSaga);
  yield takeLatest(IMPORT_FILE_APPLICATION_REQUEST, applicationFileUploadSaga);
  yield takeLatest(GET_MODULE_REQUEST, getModuleSaga);
  yield takeLatest(GET_MODULE_VERSION_REQUEST, getModuleVersionSaga);
  yield takeLatest(UPDATE_MODULE_REQUEST, updateModuleSaga);
  yield takeLatest(GET_SSH_KEY_REQUEST, getSSHKey);
  yield takeLatest(
    LIST_LIBRARY_APPLICATIONS_REQUEST,
    libraryApplicationListSaga
  );
  yield takeLatest(LIST_LIBRARY_MODELS_REQUEST, libraryModelListSaga);
  yield takeLatest(LIST_LIBRARY_RELATED_MODELS_REQUEST, libraryRelatedModelListSaga);
  yield takeLatest(
    LIST_LIBRARY_IMAGE_ANNOTATION_REQUEST,
    libraryImageAnnotationListSaga
  );
  yield takeLatest(
    LIST_LIBRARY_MODEL_TRAINING_REQUEST, libraryModelTrainingListSaga
  )
  yield takeLatest(
    LIST_LIBRARY_JOB_TRAINING_REQUEST, libraryJobTrainingListSaga
  )
  yield takeLatest(
    DETAILS_LIBRARY_JOB_TRAINING_REQUEST, libraryJobTrainingDetailsSaga
  )
  yield takeLatest(LIST_MODEL_REQUEST, libraryModelSaga);
  yield takeLatest(LIST_DATASET_REQUEST, libraryDatasetSaga);
  yield takeLatest(DETAIL_DATASET_REQUEST, libraryDatasetDetailSaga);
  yield takeLatest(LIST_ENVIRONMENT_REQUEST, libraryEnvironmentSaga);
  yield takeLatest(LIST_LIBRARY_MODULES_REQUEST, libraryModulesListSaga);
  yield takeLatest(GET_APPLICATION_DETAIL_REQUEST, getApplicationDetailSaga);
  yield takeLatest(GET_FLOW_VERSION_REQUEST, getFlowVersionSaga);
  yield takeLatest(CREATE_APPLICATION_REQUEST, createFlowSaga);
  yield takeLatest(UPDATE_APPLICATION_REQUEST, updateApplicationSaga);
  yield takeLatest(LIST_COMMITS_REQUEST, getCommits);
  yield takeLatest(NODE_RED_MODULES_REQUEST, getNodeRedModuleList);
  yield takeLatest(NODE_RED_MODULE_REQUEST, getNodeRedModule);
  yield takeLatest(SYNC_NODE_REQUEST, syncNodesSaga);
  yield takeLatest(GET_DOCKER_IMAGES_REQUEST, dockerImageSaga);
  yield takeLatest(CREATE_PUBLIC_IMAGE_DOCKER_REQUEST, createPublicDockerSaga);
  yield takeLatest(
    CREATE_PRIVATE_IMAGE_DOCKER_REQUEST,
    createPrivateDockerSaga
  );
  yield takeLatest(GET_AWS_ACCESS_KEY_REQUEST, awsAccessKeySaga);
  yield takeLatest(INSTALL_DEPENDENCIES_REQUEST, installDependencySaga);
  yield takeLatest(LIST_TEMPLATE_REQUEST, listTemplateSaga);
  yield takeLatest(
    ADD_APPLICATION_FROM_TEMPLATE_REQUEST,
    addApplicationFromTemplateSaga
  );
  yield takeLatest(ADD_VIDEO_REQUEST, addVideoSaga);
  yield takeLatest(ADD_VIDEO_FILE_REQUEST, addVideoFileSaga);
  yield takeLatest(ADD_MODEL_FILE_REQUEST, modelFileUploadSaga);
  yield takeLatest(LIST_VIDEOS_REQUEST, listVideosSaga);
  yield takeLatest(LIST_VIDEO_REQUEST, listVideoSaga);
  yield takeLatest(GET_EXPERIMENTS_REQUEST, libraryExperimentListSaga);
  yield takeLatest(LIST_LIBRARY_ANNOTATION_TASK_REQUEST, libraryAnnotationTaskListSaga);
  yield takeLatest(LIST_LIBRARY_ANNOTATION_JOB_REQUEST, libraryAnnotationJobListSaga);
  yield takeLatest(UPDATE_VIDEO_REQUEST, updateVideoSaga);
}
