import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'withRouter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { getShortLang } from 'utils/common';
import { makeSelectUser } from 'containers/App/selectors';
import { getNotificationList, getNotificationSettingList } from 'containers/Notification/actions';

import workspaceReducer from 'containers/Settings/Workspace/reducer';
import workspaceSaga from 'containers/Settings/Workspace/saga';
import {
  fetchWorkspaceRequest, fetchUserWorkspaceRequest,
} from 'containers/Settings/Workspace/actions';
import {
  makeSelectFetchWorkspace,
} from 'containers/Settings/Workspace/selectors';
import {
  fetchMeRequest,
} from 'containers/Settings/User/actions';
import {
  setWorkspaceLocaleLocally,
} from './actions';
import saga from './saga';
import reducer from './reducer';
import {
  makeSelectLocale,
  makeSelectWorkspaceLocale,
} from './selectors';

class LanguageProvider extends React.Component {
  state = {
    notificationInterval: null,
  }

  fetchInfo = () => {
    const { user } = this.props;

    this.props.getNotificationList();
    this.props.getNotificationSettingList();
    this.props.workspaceRequest({ subDomain: user.activeWorkspace || '' });
  }

  fetchInfo = () => {
    const { user } = this.props;

    this.props.getNotificationList();
    this.props.getNotificationSettingList();
    this.props.workspaceRequest({ subDomain: user.activeWorkspace || '' });
  }

  componentDidMount() {
    const { user } = this.props;

    if (user && user.activeWorkspace) {
      this.props.fetchMeRequest(() => this.props.router.navigate('/login'), () => this.fetchInfo());
    }
    this.props.setWorkspaceLocaleLocally('en');
  }

  setNotificationInterval = () => {
    const { user } = this.props;

    if (!this.state.notificationInterval) {
      const notificationIntervalId = setInterval(() => {
        if ((localStorage.accessToken) && (user && user.activeWorkspace)) {
          this.props.getNotificationList();
        }
      }, 10000);

      this.setState({ notificationInterval: notificationIntervalId });
    }
  };

  componentWillUnmount() {
    clearInterval(this.state.notificationInterval);
  }

  // eslint-disable-next-line
  async componentWillReceiveProps(nextProps) {
    const { user } = this.props;

    if (!(nextProps.user && nextProps.user.activeWorkspace)) {
      return false;
    }

    if (nextProps.user && ((nextProps.user.activeWorkspace) !== (user && user.activeWorkspace))) {

      this.props.fetchUserWorkspaceRequest(() => {
        this.props.fetchMeRequest(() => this.props.router.navigate('/login'), () => {
          this.props.getNotificationList();
          this.props.getNotificationSettingList();
          this.setNotificationInterval();
          this.props.workspaceRequest({ subDomain: nextProps.user.activeWorkspace || '' });
        });
      });
    }

    if (nextProps.workspace && nextProps.workspace.defaultLanguage) {
      const { defaultLanguage } = nextProps.workspace;

      this.props.setWorkspaceLocaleLocally(getShortLang[defaultLanguage]);
    }
  }

  render() {
    const { props } = this;

    return (
      <IntlProvider
        locale={props.locale || props.localeWorkspace}
        key={props.locale || props.localeWorkspace}
        messages={props.messages[props.locale || props.localeWorkspace]}
      >
        {React.Children.only(props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  localeWorkspace: PropTypes.string,
  messages: PropTypes.object,
  workspace: PropTypes.object,
  children: PropTypes.element.isRequired,
  workspaceRequest: PropTypes.func,
  setWorkspaceLocaleLocally: PropTypes.func,
};

const withReducer = injectReducer({ key: 'locale', reducer });
const withSaga = injectSaga({ key: 'locale', saga });

const withWorkspaceReducer = injectReducer({
  key: 'workspace',
  reducer: workspaceReducer,
});
const withWorkspaceSaga = injectSaga({
  key: 'workspace',
  saga: workspaceSaga,
});

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  user: makeSelectUser(),
  localeWorkspace: makeSelectWorkspaceLocale(),
  workspace: makeSelectFetchWorkspace(),
});

const withConnect = connect(
  mapStateToProps,
  {
    fetchMeRequest,
    getNotificationList,
    getNotificationSettingList,
    fetchUserWorkspaceRequest,
    workspaceRequest: fetchWorkspaceRequest,
    setWorkspaceLocaleLocally,
  },
);

export default compose(
  withRouter,
  withReducer,
  withWorkspaceReducer,
  withSaga,
  withWorkspaceSaga,
  withConnect,
)(LanguageProvider);
