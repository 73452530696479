import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Compress from 'compress.js';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import { truncate } from 'utils/common';

import messages from 'utils/messages';
import { UUID_LENGTH } from 'utils/constants';
import { UploaderClose, UpCevron, DownCevron, UploadTick } from 'images/common';

let currentIndex = 0;

const ImageUploader = ({
  onUpdate,
  data,
  onRemove,
  hideClose,
  color,
  uploading,
  title,
  icon,
  onClose,
  dataCy,
  files,
  message
}) => {
  const [collapse, setCollapse] = useState(false)

  const fileLength = (files && files.length) || 0;
  const uploadedFiles = fileLength && files.filter(item => item.progress === 100);

  const completedPerecentage = (uploadedFiles.length / fileLength) * 100;

  const time = (files && files[currentIndex] && files[currentIndex].time) || '00m 00s';
  const remainingBytes = files && files[currentIndex].remainingBytes;

  return (
    <>
      <div className='image-uploader__wrapper'>
        <div className='image-uploader__header'>
          <div className='image-uploader__header_title'>Uploading {fileLength} items</div>
          <div className='image-uploader__cancel'>
            <div className='image-uploader__cancel_up' onClick={() => setCollapse(!collapse)}><UpCevron /></div>
            {/* <div className="cursor" onClick={onClose}><UploaderClose /></div> */}
          </div>
        </div>
        <div
          className="image-uploader__progressbar"
          style={{
            width: `${completedPerecentage}%`,
          }}
        ></div>

        <div className='image-uploader__status'>
          <div>{time} left • {parseInt(completedPerecentage, 10)} %</div>
          {/* <div className='image-uploader__cancel'>Cancel</div> */}
        </div>
        <div className={classNames('', {
          'image-uploader__collapase': collapse,
          'image-uploader__expand': !collapse
        })}>
          <Scrollbars
            autoHeight
            autoHide
            thumbSize={1}
            autoHideDuration={1}
            autoHeightMax={150}
            autoHeightMin={100}
          >
            {files.map((file, index) => {
              currentIndex = index;
              return (
                <div className='image-uploader__items'>
                  <div>{file.item && file.item.path && truncate(file.item.path, 40)}</div>
                  {file.progress === 100 ?
                    <div className={classNames('', {
                    })}>
                      <UploadTick />
                    </div>
                    :
                    <div className={classNames('', {
                      'image-uploader__pending': file.progress !== 100
                    })}>
                    </div>
                  }
                </div>
              )
            })}
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

ImageUploader.propTypes = {
  workspace: PropTypes.object,
  removeLogo: PropTypes.func,
  uploading: PropTypes.bool,
  addLogo: PropTypes.func,
};

export default compose()(ImageUploader);
