import classNames from "classnames";
import Box from "components/Detail/Box";
import Modal from "components/Modal";
import {
  logout,
  updateDeviceType,
  updateNotificationDisplay,
  updateSidebarDisplay,
} from "containers/App/actions";
import reducer from "containers/App/reducer";
import {
  getNotificationState,
  getSidebarStatus,
  makeSelectDevice,
  makeSelectUser,
} from "containers/App/selectors";
import Accordion from "components/Accordion";
import {
  NotificationNew,
  Home,
  ArrowLeft,
  CarbonForIbmProduct,
  ChartLineData,
  Settings,
  Development,
  ObjectStorage,
  InstanceClassic,
  AreaCustom,
  MachineLearningModel,
  DataVis_1,
  DataVis_2,
  UserAvatar,
  UserMultiple,
  Password,
  Security,
  Terminal,
  MediaLibrary,
  CollapseAll,
  ChartColumn,
  Portfolio,
  Store,
  Layers,
  Cube,
  WorkflowAutomation,
  LoadBalancerGlobal,
} from "@carbon/react/icons";
import {
  ChevronsDown,
  ChevronsUp,
  Check,
  ChevronDown,
  ChevronRight,
} from "react-feather";
import { selectWorkspaceRequest } from "containers/Login/actions";
import loginReducer from "containers/Login/reducer";
import loginSaga from "containers/Login/saga";
import { getDetailRole } from "containers/Settings/AccessRole/selectors";
import { makeSelectMe } from "containers/Settings/User/selectors";
import {
  getStartedTrackerRequest,
  updateStartedTrackerRequest,
} from "containers/Settings/Workspace/actions";
import DeactivatedWorkspace from "containers/Settings/Workspace/DeactivatedWorkspace";
import SuspendedWorkspace from "containers/Settings/Workspace/SuspendedWorkspace";
import IdleScreenDetector from "../IdleScreenDetector";
import {
  getTrackerInfo,
  makeSelectFetchWorkspace,
  makeSelectLoadingTracker,
  makeSelectUserWorkspace,
} from "containers/Settings/Workspace/selectors";
import { getNotifications } from "containers/Notification/selectors";

import { defaultNotifier } from "functions/notificationHandler";
import {
  AddWorkspaceIcon,
  RightCevronIcon,
  WorkspaceIcon,
} from "images/common";
import localForage from "localforage";
import PropTypes from "prop-types";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile } from "react-device-detect";

import { FormattedMessage } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {withRouter} from 'withRouter';
import slugify from "react-slugify";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { routeMapping } from "routes";
import { socket } from "socket";
import { getInitials } from "utils/common";
import {
  DASHBOARD_ACCENT_COLOR,
  DEFAULT_ACCENT,
  LIBRARY_ACCENT,
  SOLUTION_ACCENT,
  EXTERNAL_URL,
  APP_SESSION_LOGOUT
} from "utils/constants";
import history from "utils/history";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import appMessages from "utils/messages";
import { isPermitted } from "utils/permissions";
import { getVendorDetailsState } from "containers/Partner/selectors";
import VerificationCode from "containers/Settings/User/Detail/components/VerificationCode";
import { EmptyNotificationIcon, HelpContact, SupportContact } from "../../images/common";
import GetStarted from "../GetStarted";
import NoCodeEditorIcon from "./icons/noCodeEditor";
import PreApplicationWrapper from "./PreApplicationWrapper";
import PreLoaderSidebar from "../../components/PreLoader/PreLoaderSidebar";
import { getPendingDeviceRequest } from 'containers/Deployment/actions';
import reducerDep from 'containers/Deployment/reducer'
import sagaDep from 'containers/Deployment/saga'
import { getPendingDeviceList } from 'containers/Deployment/selectors';


class LeftPanel extends React.Component {
  constructor(props) {
    super(props);
    this.navHead = React.createRef();
    this.navTopMenu = React.createRef();
    this.navBottomMenu = React.createRef();
    this.navHeight = React.createRef();
    this.navStaticHeight = React.createRef();
    this.navDynamicHeight = React.createRef();
  }

  state = {
    isActive: false,
    showWarning: false,
    scrollTop: 0,
    showSupportModal: false,
    showGetStartedModal: false,
    openAll: true,
    openSettingAll: true,
    isChanged: false,
    showButtonBox: true,
  };

  onExpandAll = () => {
    if (!this.state.isChanged) {
      this.setState({ isChanged: true });
    } else {
      this.setState({ isChanged: false });
    }
    this.setState({ openAll: true });
    this.setState({ openSettingAll: true });
  };

  onCollapseAll = () => {
    if (!this.state.isChanged) {
      this.setState({ isChanged: true });
    } else {
      this.setState({ isChanged: false });
    }
    this.setState({ openAll: false });
    this.setState({ openSettingAll: false });
  };

  logout = async () => {
    this.props.logout();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    localForage.setItem("activePartnerId", null);
    localForage.setItem("hideMaintenanceBanner", false);

    setTimeout(() => {
      this.props.router.navigate(routeMapping.LOGIN.path);
    }, 100);
  };

  logoutSession = () => {
    this.logout();
    return defaultNotifier('Session timed out.');
  }

  updateShowGetStarted = (payload) => {
    if (payload) {
      this.props.getStartedTrackerRequest();
    }

    this.setState({ showGetStartedModal: payload });
  };

  toggleWorkspaceSelection = () => {
    this.setState({
      isActive: !this.state.isActive,
      showSwitchWorkspace: false,
    });
  };

  hideNav = () => {
    document.getElementById("sidebar").classList.remove("sidebar_visible");
    document.getElementById("overlay").classList.remove("overlay_visible");
  };

  getActiveClass = (link, activeClass) => {
    const hash = window.location.pathname;

    if (hash.split("/").length - 1 === 1 && link === "/") {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    if (link !== "/" && hash.includes(link)) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    return `primary-nav__link ${activeClass}`;
  };

  getChildActiveClass = (link, activeClass) => {
    const hash = window.location.pathname;
    const { workspace } = this.props;

    // Activate sub header when header is selected.
    const DEFAULT_ACTIVE_SUB_HEADERS = {
      [`/${workspace && slugify(workspace.name)}${routeMapping.DEPLOYMENT.path
        }`]: routeMapping.DEPLOYMENT_PROFILES.path,
      [`/${workspace && slugify(workspace.name)}${routeMapping.LIBRARY.path}`]:
        routeMapping.LIBRARY_APPLICATIONS.path,
    };

    if (
      DEFAULT_ACTIVE_SUB_HEADERS[hash] &&
      DEFAULT_ACTIVE_SUB_HEADERS[hash] === link
    ) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    if (link !== "/" && hash.includes(link)) {
      return `primary-nav__link primary-nav__link_active ${activeClass}`;
    }

    return `primary-nav__link ${activeClass}`;
  };

  openNotification = () => {
    this.props.updateNotificationDisplay(!this.props.showNotification);
  };

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const isNextSmallerDevice =
      nextProps.deviceSizeType === "tablet" ||
      nextProps.deviceSizeType === "mobile";
    const isSmallerDevice =
      this.props.deviceSizeType === "tablet" ||
      this.props.deviceSizeType === "mobile";

    const { tracker } = this.props;

    this.handleWindowSizeChange();

    if (
      nextProps.deviceSizeType === "mobile" &&
      nextProps.deviceSizeType !== this.props.deviceSizeType
    ) {
      return this.props.updateSidebarDisplay(true);
    }
    if (
      tracker &&
      nextProps.tracker &&
      tracker.completedCount !== nextProps.tracker.completedCount &&
      nextProps.tracker.completedCount === nextProps.tracker.taskCount
    ) {
      return defaultNotifier({ ...appMessages.congratulationsSetupComplete });
    }

    if (
      !isSmallerDevice &&
      nextProps.deviceSizeType === "tablet" &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      isNextSmallerDevice
    ) {
      return this.props.updateSidebarDisplay(true);
    }

    if (
      isSmallerDevice &&
      nextProps.deviceSizeType !== this.props.deviceSizeType &&
      nextProps.deviceSizeType === "desktop"
    ) {
      return this.props.updateSidebarDisplay(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => { };

  handleWindowSizeChangeTimeset = () => {
    setTimeout(() => {
      this.handleWindowSizeChange();
    }, 1);
  };

  componentDidMount() {
    const {workspace} = this.props;

    this.props.updateDeviceType({
      width: window.innerWidth,
      isMobile: isMobile,
    });
    window.addEventListener("resize", this.handleWindowSizeChange);

    this.handleWindowSizeChange();

    if (this.props.deviceSizeType === "mobile") {
      return this.props.updateSidebarDisplay(true);
    }

    if (this.props.me && isPermitted({ id: "devices" }, this.props.detailRole)) {
      this.props.getPendingDeviceRequest({
        includeDemoData: workspace.demoModeEnabled
      });
    }
  }

  // client = new ApolloClient({
  //   link: new WebSocketLink({
  //     uri: `wss://${hostname}/api/workspace/ws/`,
  //     options: {
  //       reconnect: true,
  //       transports: ['websocket'],
  //       connectionParams: {
  //         headers: {
  //           authorization: `Bearer ${localStorage.getItem('accessToken')}`
  //         }
  //       }
  //     }
  //   }),
  //   cache: new InMemoryCache()
  // });

  onSubmitCode = (codes, type) => {
    const code = codes.reduce((sum, item) => `${sum}${item}`);

    let payload = this.state.wsData;

    if (type === "code") {
      payload = {
        ...this.state.wsData,
        twoFactorCode: code,
      };
    }

    if (type === "recoveryKey") {
      payload = {
        ...this.state.wsData,
        twoFactorRecoveryKey: code,
      };
    }

    return this.props.selectWorkspaceRequest(payload, {
      push: this.props.router.navigate,
      onCallback: () => {
        socket.close();
        this.setState({ showVerificationCode: false });
      },
    });
  };

  onSelectWorkspace = (data) => {
    const { workspaces } = this.props;

    this.toggleWorkspaceSelection();

    const args = {
      subDomain: data,
    };
    const workspace = workspaces.find((item) => item.subDomain === data);

    if (
      workspace &&
      workspace.status === "inactive" &&
      workspace.userRole !== "Owner"
    ) {
      return this.setState({ selectedWs: workspace, showWarning: true });
    }

    if (
      workspace &&
      workspace.status === "inactive" &&
      workspace.userRole === "Owner"
    ) {
      return this.setState({ selectedWs: workspace, isSuspended: true });
    }

    if (workspace.userRole === "Owner" || workspace.status === "active") {
      if (workspace.twoFactorEnabled) {
        this.setState({ showVerificationCode: true });
        return this.setState({ wsData: args });
      }

      socket.close();
      return this.props.selectWorkspaceRequest(args, {
        push: this.props.router.navigate,
      });
    }

    this.setState({ selectedWs: workspace, showWarning: true });
  };

  onCreate = () => {
    this.props.router.navigate(routeMapping.NEW_WORKSPACE.path);
  };

  redirectToUserPage = (e) => {
    e.stopPropagation();
    const { user, workspace } = this.props;

    if (user) {
      this.setState({ isActive: false });
      this.props.router.navigate(
        `/${workspace && slugify(workspace.name)}/settings/profile`
      );
    }
  };

  redirectToUserListPage = (e) => {
    const { workspace } = this.props;
    e.stopPropagation();

    if (isPermitted({ id: "users" }, this.props.detailRole)) {
      this.setState({ isActive: false });
      this.props.router.navigate(
        `/${workspace && slugify(workspace.name)}${routeMapping.SETTINGS_USER.path
        }`
      );
    }
  };

  redirectToWorkspace = (e) => {
    e.stopPropagation();
    const { workspace } = this.props;

    if (workspace) {
      this.setState({ isActive: false });
      this.props.router.navigate(
        `/${workspace && slugify(workspace.name)}/settings/workspace`
      );
    }
  };

  onShowWorkspaceSwitch = (e) => {
    const { workspaces, user } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (workspaces.length > 1 || user.workspaceSetupInvited) {
      this.setState({ showSwitchWorkspace: !this.state.showSwitchWorkspace });
    }
  };

  onOutsideClick = () => {
    const { deviceSizeType } = this.props;

    this.setState({ isActive: false });

    if (deviceSizeType === "mobile" && !this.props.sidebarStatus) {
      this.props.updateSidebarDisplay(true);
    }
  };

  onCreateDev = () => {
    this.props.router.navigate(
      `${routeMapping.NEW_WORKSPACE.path}?isCreatePage=true`
    );
  };

  onNavigateToHelp = () => {
    this.setState({ showSupportModal: false });

    return window.open("https://support.viso.ai/");
  };

  onNavigateToMail = () => {
    this.setState({ showSupportModal: false });
  };

  onCloseModal = () => {
    this.setState({ showSupportModal: false });
  };

  onShowModal = () => {
    this.setState({ showSupportModal: true });
  };

  handleScrollStart = () => {
    this.setState({ isScrolling: true });
  };

  handleScrollStop = () => {
    this.setState({ isScrolling: false });
  };

  renderModal = () => (
    <Modal
      classNames="modal__frame5"
      title="Viso Support"
      body={
        <>
          <div className="billing-modal-title">
            <span className="billing-info-explore">
              Create a support request and let us know what you need assistance
              with. Find step-by-step guides, tutorials and documentation in the
              Help Center.
            </span>
          </div>
          <div className="box-container__wrapper">
            <Box
              title="Help Center"
              onClickBtn={this.onNavigateToHelp}
              logo={<HelpContact />}
              desc="Search guides, tutorials and documentation."
            />
            <Box
              title="Contact Support"
              to="mailto:support@viso.ai"
              logo={<SupportContact />}
              onClickBtn={this.onNavigateToMail}
              desc="Create a support request and ask your questions."
            />
          </div>
        </>
      }
      onClose={this.onCloseModal}
    />
  );

  // Routes displayed in the left panel.
  navLinks = [
    {
      icon: <DataVis_1 />,
      id: "videos",
      link: routeMapping.LIBRARY_VIDEOS.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.dataCollection} />,
    },
    {
      icon: <DataVis_2 />,
      id: "dataset",
      // beta: true,
      link: routeMapping.LIBRARY_DATASETS.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.datasets} />,
    },
    {
      icon: <AreaCustom />,
      id: "imageAnnotation",
      beta: true,
      link: routeMapping.ANNOTATION_TAKS.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.imageAnnotation} />,
    },
    {
      icon: <CarbonForIbmProduct />,
      id: "modelTraining",
      // beta: true,
      link: routeMapping.EXPERIMENTS.path,
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      name: <FormattedMessage {...appMessages.modelTraining} />,
    },
    {
      icon: <MachineLearningModel />,
      id: "models",
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.LIBRARY_MODELS.path,
      name: <FormattedMessage {...appMessages.aiModels} />,
    },
  ];

  navLinks1 = [
    {
      icon: <Cube />,
      id: "modules",
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.LIBRARY_MODULES.path,
      name: <FormattedMessage {...appMessages.buildingBlocks} />,
    },
    {
      icon: <NoCodeEditorIcon />,
      id: "application-edit",
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.APPLICATIONS_NO_CODE_PAGE.path,
      name: <FormattedMessage {...appMessages.noCodeEditor} />,
    },
    {
      icon: <Development />,
      id: "applications",
      class: `primary-nav__link_${LIBRARY_ACCENT}`,
      link: routeMapping.LIBRARY_APPLICATIONS.path,
      name: <FormattedMessage {...appMessages.applications} />,
    },
  ];

  navLinks2 = [
    {
      icon: <InstanceClassic />,
      id: "devices",
      deviceEnrollment: true,
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.PENDING_DEVICE_TYPE_LIST.path,
      name: <FormattedMessage {...appMessages.deviceEnrollment} />,
    },
    {
      icon: <ObjectStorage />,
      id: "devices",
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.DEPLOYMENT_DEVICES.path,
      name: <FormattedMessage {...appMessages.deviceManagement} />,
    },
    {
      icon: <LoadBalancerGlobal />,
      id: "profiles",
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.DEPLOYMENT_PROFILES.path,
      name: <FormattedMessage {...appMessages.deploymentCenter} />,
    },
    {
      icon: <WorkflowAutomation />,
      id: "device-configuration",
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.DEPLOYMENT_DEVICES_MANAGER.path,
      name: <FormattedMessage {...appMessages.configurations} />,
    },
  ];

  navLinks3 = [
    {
      icon: <ChartLineData />,
      id: "dashboard",
      class: `primary-nav__link_${DASHBOARD_ACCENT_COLOR}`,
      link: routeMapping.DASHBOARD.path,
      name: <FormattedMessage {...appMessages.monitoring} />,
    },
    {
      icon: <Settings />,
      id: "settings",
      link: routeMapping.SETTINGS.path,
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      name: <FormattedMessage {...appMessages.settings} />,
    },
  ];

  navLinksSetting = [
    {
      icon: <UserAvatar />,
      id: "settings",
      link: routeMapping.PROFILE.path,
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      name: <FormattedMessage {...appMessages.myProfile} />,
    },
    {
      icon: <Layers />,
      id: "myWorkspaces",
      dataCy: "my-workspace",
      link: routeMapping.MANAGE_WORKSPACE.path,
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      name: <FormattedMessage {...appMessages.myWorkspaces} />,
    },
  ];

  navLinksSetting1 = [
    {
      icon: <UserMultiple />,
      id: "users",
      dataCy: "my-user",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.SETTINGS_USER.path,
      name: <FormattedMessage {...appMessages.userManagement} />,
    },
    {
      icon: <Password />,
      id: "role",
      dataCy: "my-role",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.SETTINGS_ROLE.path,
      name: <FormattedMessage {...appMessages.rolesAndAccess} />,
    },
    {
      icon: <Security />,
      id: "authentication",
      dataCy: "authentication",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.AUTHENTICATION.path,
      name: <FormattedMessage {...appMessages.authentication} />,
    },
    {
      icon: <Terminal />,
      id: "terminalAccess",
      dataCy: "terminal-access",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: `${routeMapping.SETTINGS.path}/terminal`,
      name: <FormattedMessage {...appMessages.terminalAccess} />,
    },
    {
      icon: <MediaLibrary />,
      id: "videoViewer",
      dataCy: "video-viewer",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: `${routeMapping.SETTINGS.path}/video-access`,
      name: <FormattedMessage {...appMessages.videoViewerAccess} />,
    },
    {
      icon: <Security />,
      id: "audit",
      dataCy: "audit",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: `${routeMapping.SETTINGS.path}/audit-log`,
      name: <FormattedMessage {...appMessages.auditLog} />,
    },
  ];

  navLinksSetting2 = [
    {
      icon: <Settings />,
      id: "workspace",
      dataCy: "workspace-settings",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.WORKSPACE.path,
      name: <FormattedMessage {...appMessages.workspaceSettings} />,
    },
    {
      icon: <CollapseAll />,
      id: "billing",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.BILLING.path,
      name: <FormattedMessage {...appMessages.billingAndSubscriptions} />,
    },
    {
      icon: <ChartColumn />,
      id: "planLimitsUsage",
      class: `primary-nav__link_${SOLUTION_ACCENT}`,
      link: routeMapping.BILLING_QUOTA.path,
      name: <FormattedMessage {...appMessages.planLimitsUsage} />,
    },
  ];

  navLinksSetting3 = [
    {
      icon: <Portfolio />,
      id: "partner",
      dataCy: "partner-settings",
      class: `primary-nav__link_${DEFAULT_ACCENT}`,
      link: routeMapping.PARTNER.path,
      name: <FormattedMessage {...appMessages.partnerPortal} />,
    },
    {
      icon: <Store />,
      id: "marketplace",
      class: `primary-nav__link_${DASHBOARD_ACCENT_COLOR}`,
      link: routeMapping.MARKETPLACE.path,
      name: <FormattedMessage {...appMessages.marketplace} />,
    },
  ];

  handleScroll = (payload) => {
    this.setState({ scrollHeight: payload.top });
    if (payload.top < 0.99) {
      this.setState({ showButtonBox: true });
    } else {
      this.setState({ showButtonBox: false });
    }

    if (payload.scrollTop !== 0) {
      this.setState({ isScrolling: true });
      return this.setState({ scrollTop: payload.scrollTop });
    }

    this.setState({ isScrolling: false });
    this.setState({ scrollTop: payload.scrollTop });
  };

  onClose = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");

    this.props.router.navigate(routeMapping.LOGIN.path);
  };

  render() {
    const {
      sidebarStatus,
      workspace,
      workspaces,
      user,
      me,
      location,
      notifications,
      tracker = {},
      hasBanner,
      router,
      vendor,
    } = this.props;


    const pendingDevices = (this.props.pendingDevices && this.props.pendingDevices.data && this.props.pendingDevices.data.filter(item => item.approvalStatus === 'pending')) || []

    const {
      showGetStartedModal,
      navbarHeight,
      navbarBottom,
      navbarTop,
      navbarHeader,
    } = this.state;

    const navStaticHeight =
      this.navStaticHeight &&
      this.navStaticHeight.current &&
      this.navStaticHeight.current.offsetHeight;

    const navTopHeight =
      this.navTopMenu &&
      this.navTopMenu.current &&
      this.navTopMenu.current.offsetHeight;

    const navDynamicHeight =
      this.navDynamicHeight &&
      this.navDynamicHeight.current &&
      this.navDynamicHeight.current.offsetHeight;

    const isSettingPage =
      router.location.pathname.includes("/settings") ||
      router.location.pathname.includes("/marketplace") ||
      router.location.pathname.includes("/solution") ||
      router.location.pathname.includes("/authentication") ||
      router.location.pathname.includes("/partner");

    const fullName = user.firstName
      ? `${user.firstName.trim() || ""} ${user.lastName.trim() || ""}`
      : "";
    const displayName = (fullName && fullName.trim()) || user.email || "-";

    const sidebarClass = classNames("sidebar", {
      collapsed: sidebarStatus,
      loading: this.props.isLoading,
      'sidebar-xl-footer': hasBanner,
    });

    const activeWorkspace = classNames("workspace-nav__toggle h4", {
      active: this.state.isActive,
    });

    const selectionWorkspace = classNames(
      "workspace-nav__dropdown h4 c-label",
      {
        active: this.state.isActive,
      }
    );

    if (this.props.isLoading) {
      return <PreLoaderSidebar />;
    }

    if (this.state.showWarning) {
      return <DeactivatedWorkspace workspace={this.state.selectedWs} />;
    }

    if (this.state.isSuspended) {
      return (
        <SuspendedWorkspace
          workspace={this.state.selectedWs}
          onClose={this.onClose}
        />
      );
    }

    const value =
      (this.props.tracker && this.props.tracker.completedCount) || 0;

    const navIconClass = classNames("left-panel-started", {
      active: this.state.showGetStartedModal,
    });

    const navBottomClass = classNames("primary-nav primary-nav_bottom", {
      "primary-nav_bottom_wrapper":
        navbarHeight > navbarTop + navbarHeader + 180,
    });

    const isCompleted =
      (tracker && tracker.taskCount) === (tracker && tracker.completedCount);

    if (this.state.showDemoApp) {
      return (
        <PreApplicationWrapper
          getStartedTrackerRequest={getStartedTrackerRequest}
          updateStartedTrackerRequest={updateStartedTrackerRequest}
          onClose={() => this.setState({ showDemoApp: false })}
        />
      );
    }

    if (this.state.showVerificationCode) {
      return (
        <VerificationCode
          onSubmit={this.onSubmitCode}
          workspace={workspace}
          onClose={() => this.setState({ showVerificationCode: false })}
        />
      );
    }

    return (
      <>
        {/* {me && workspace && workspace.id && (
          // <ApolloProvider client={this.client}>
          <GetStarted
            onShowModal={this.onShowModal}
            showDemoApp={this.state.showDemoApp}
            onShowDemoApp={() => this.setState({ showDemoApp: true })}
            showGetStartedModal={showGetStartedModal}
            onClose={() => this.setState({ showGetStartedModal: false })}
            color={DEFAULT_ACCENT}
            workspaceId={workspace.id}
          />
          // </ApolloProvider>
        )} */}
        <IdleScreenDetector logout={this.logoutSession} />
        <div
          id="overlay"
          className="overlay mobile-only"
          onClick={this.hideNav}
        />
        {/* {deviceSizeType === 'mobile' && sidebarStatus && (
          <div className="mobile-only overlay overlay_visible" />
        )} */}
        <aside id="sidebar" className={sidebarClass} ref={this.navHeight}>
          <div className={classNames("sidebar__header", {})} ref={this.navHead}>
            <div className="sidebar__head">
              {sidebarStatus ? (
                <div className="sidebar__title h1 c-label">
                  viso
                  <br />
                  <span className="sidebar__title_sec">suite</span>
                </div>
              ) : (
                <div className="sidebar__title h1 c-label">
                  viso<span className="sidebar__title_sec">suite</span>
                </div>
              )}
            </div>
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
              <nav
                className={classNames("workspace-nav", {
                  "workspace-nav__active": this.state.isActive,
                })}
                data-cy="navbar-settings"
                onClick={this.toggleWorkspaceSelection}
              >
                <button type="button" className={activeWorkspace}>
                  {!sidebarStatus && (
                    <div
                      className="header mx-b-3 w-84"
                    // onClick={this.redirectToWorkspace}
                    >
                      {(workspace && workspace.name) || "-"}
                    </div>
                  )}
                  {!sidebarStatus && (
                    <div
                      className="h5 c-label sub-header w-84"
                    // onClick={this.redirectToUserPage}
                    >
                      {displayName}
                    </div>
                  )}
                  <div
                    className={`${workspace && workspace.logo && "no-border"
                      } workspace-nav__icon`}
                  >
                    {workspace && workspace.logo ? (
                      <img
                        src={workspace.logo}
                        alt="Workspace logo"
                        height="45"
                        width="45"
                      />
                    ) : (
                      <WorkspaceIcon />
                    )}
                  </div>
                  {!sidebarStatus && (
                    <span className="workspace-icon__cevron">
                      <ChevronDown size={18} />
                    </span>
                  )}
                </button>
                <div className={selectionWorkspace}>
                  {!sidebarStatus && (
                    <div className="workspace-selector">
                      <button
                        type="button"
                        className={activeWorkspace}
                        onClick={this.toggleWorkspaceSelection}
                      >
                        <div
                          className="header"
                        // onClick={this.redirectToWorkspace}
                        >
                          {(workspace && workspace.name) || "-"}
                        </div>
                        <div
                          className="h5 c-label"
                        // onClick={this.redirectToUserListPage}
                        >
                          {workspace && workspace.userCounts}{" "}
                          <FormattedMessage {...appMessages.sUsers} />
                        </div>
                        <div
                          className={`${workspace && workspace.logo && "no-border"
                            } workspace-nav__icon`}
                        >
                          {workspace && workspace.logo ? (
                            <img
                              src={workspace.logo}
                              alt="Workspace logo"
                              height="45"
                              width="45"
                            />
                          ) : (
                            <WorkspaceIcon />
                          )}
                        </div>
                      </button>
                    </div>
                  )}
                  <nav>
                    {isPermitted({ id: "users" }, this.props.detailRole) && (
                      <Link
                        to={`/${workspace && slugify(workspace.name)}${routeMapping.WORKSPACE.path
                          }`}
                        onClick={this.toggleWorkspaceSelection}
                        className={"workspace-nav__link"}
                      >
                        <FormattedMessage {...appMessages.workspaceSettings} />
                      </Link>
                    )}
                    {isPermitted({ id: "users" }, this.props.detailRole) && (
                      <Link
                        to={`/${workspace && slugify(workspace.name)}${routeMapping.SETTINGS_USER.path
                          }`}
                        onClick={this.toggleWorkspaceSelection}
                        className={"workspace-nav__link"}
                      >
                        <FormattedMessage {...appMessages.members} />
                      </Link>
                    )}
                    {isPermitted({ id: "users" }, this.props.detailRole) && (
                      <Link
                        to={`/${workspace && slugify(workspace.name)}${routeMapping.BILLING.path
                          }`}
                        onClick={this.toggleWorkspaceSelection}
                        className={"workspace-nav__link"}
                      >
                        <FormattedMessage {...appMessages.billingCenter} />
                      </Link>
                    )}
                    <a
                      href={EXTERNAL_URL.support}
                      rel="noreferrer"
                      target="_blank"
                      onClick={() => {
                        this.toggleWorkspaceSelection();
                        this.onShowModal();
                      }}
                      className={"workspace-nav__link"}
                    >
                      <FormattedMessage {...appMessages.support} />
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={this.onShowWorkspaceSwitch}
                      className={classNames(
                        "workspace-nav__link vertical-align space-between relative",
                        {
                          "workspace-nav__active":
                            this.state.showSwitchWorkspace,
                          actions__option_disabled:
                            workspaces.length <= 1 &&
                            !user.workspaceSetupInvited,
                        }
                      )}
                    >
                      <FormattedMessage {...appMessages.switchWorkspace} />
                      <ChevronRight size={18} />
                      {this.state.showSwitchWorkspace && (
                        <div className="workspace-selector-wrapper">
                          <Scrollbars
                            autoHeight
                            className="custom-scrollbars"
                            autoHeightMax={310}
                            universal
                            renderThumbVertical={(props) => (
                              <div {...props} className="thumb-vertical" />
                            )}
                          >
                            {workspaces &&
                              workspaces
                                .filter(
                                  (ws) => ws.id !== (workspace && workspace.id)
                                )
                                .map((ws, index) => (
                                  <div
                                    className="workspace-nav__option"
                                    key={index}
                                    onClick={() =>
                                      this.onSelectWorkspace(ws.subDomain)
                                    }
                                  >
                                    <span>
                                      {ws.name || ws.organizationName}
                                    </span>
                                    <div
                                      className={`${ws.logo && "no-border"
                                        } workspace-nav__icon`}
                                    >
                                      {ws && ws.logo ? (
                                        <img
                                          src={ws.logo}
                                          alt="Workspace logo"
                                          height="45"
                                          width="45"
                                        />
                                      ) : (
                                        <WorkspaceIcon />
                                      )}
                                    </div>
                                  </div>
                                ))}
                            {/* <div
                              className="workspace-nav__option label-color"
                              onClick={this.onCreate}
                            >
                              <span>
                                <FormattedMessage
                                  {...appMessages.createWorkspace}
                                />
                              </span>
                              <div className="workspace-nav__icon workspace-nav__plus_icon">
                                <AddWorkspaceIcon />
                              </div>
                            </div> */}
                            {user.workspaceSetupInvited && (
                              <>
                                <div
                                  className="workspace-nav__option label-color"
                                  onClick={this.onCreateDev}
                                >
                                  <span>Set up your workspace</span>
                                  <div className="workspace-nav__icon workspace-nav__plus_icon">
                                    <AddWorkspaceIcon />
                                  </div>
                                </div>
                              </>
                            )}
                          </Scrollbars>
                        </div>
                      )}
                    </a>
                    <hr className="mx-h-6" />
                    {!sidebarStatus && (
                      <div className="user-selector">
                        <button
                          type="button"
                          className={activeWorkspace}
                          onClick={this.toggleWorkspaceSelection}
                        >
                          <div
                            className="header"
                            onClick={this.redirectToUserPage}
                          >
                            {displayName || "-"}
                          </div>
                          <div
                            className="h5 c-label sub-header"
                            onClick={this.redirectToUserPage}
                          >
                            {user.email}
                          </div>
                          <div className="user-nav__icon">
                            <div className="checkbox__image">
                              {getInitials(user.firstName, user.lastName)}
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                    <Link
                      to={`/${workspace && slugify(workspace.name)}${routeMapping.PROFILE.path
                        }`}
                      onClick={this.toggleWorkspaceSelection}
                      className={"workspace-nav__link"}
                    >
                      <FormattedMessage {...appMessages.userSettings} />
                    </Link>
                    <a
                      href="javascript:void(0)"
                      onClick={this.logout}
                      className="workspace-nav__link"
                    >
                      <FormattedMessage {...appMessages.logout} />
                    </a>
                  </nav>
                </div>
              </nav>
            </OutsideClickHandler>
          </div>
          <div
            ref={this.navStaticHeight}
            className={classNames("primary-nav-wrapper", {
              "primary-nav-wrapper_scroll": this.state.isScrolling,
            })}
          >
            <Scrollbars
              universal
              style={{ width: "100%" }}
              autoHide
              autoHideDuration={1}
              onScrollFrame={this.handleScroll}
              renderThumbVertical={(props) => <div {...props} className="" />}
            >
              <nav className="primary-nav" ref={this.navDynamicHeight}>
                {!isSettingPage && (
                  <>
                    <div className="nav-item nav-sidepanel-loader">
                      {sidebarStatus && !this.state.isActive && (
                        <div
                          className="nav-item__tooltip"
                          style={{ marginTop: -this.state.scrollTop }}
                        >
                          <p className="app-tooltip">
                            <FormattedMessage {...appMessages.dashboard} />
                          </p>
                        </div>
                      )}
                      <Link
                        to="/"
                        className={this.getActiveClass(
                          "/",
                          `primary-nav__link primary-nav__link_${DEFAULT_ACCENT}`
                        )}
                      >
                        <Home />
                        <FormattedMessage {...appMessages.dashboard} />
                      </Link>
                    </div>
                    <div className="nav-item nav-item-last nav-sidepanel-loader">
                      {sidebarStatus && !this.state.isActive && (
                        <div
                          className="nav-item__tooltip"
                          style={{ marginTop: -this.state.scrollTop }}
                        >
                          <p className="app-tooltip">
                            <FormattedMessage {...appMessages.notifications} />
                          </p>
                        </div>
                      )}
                      <a
                        href="javascript:void(0)"
                        onClick={this.openNotification}
                        className={`primary-nav__link primary-nav__link_${DEFAULT_ACCENT} 
                        primary-nav__link_notification`}
                      >
                        {(notifications && notifications.unreadCount) ? <NotificationNew /> : <EmptyNotificationIcon/>}
                        {!sidebarStatus &&
                          notifications &&
                          !!notifications.unreadCount && (
                            <span
                              className={classNames("primary-nav__badge", {
                                "primary-nav__badge_plus":
                                  notifications.unreadCount > 9,
                              })}
                            >
                              {notifications.unreadCount > 99
                                ? "99"
                                : notifications.unreadCount}
                            </span>
                          )}
                        <FormattedMessage {...appMessages.notifications} />
                      </a>
                    </div>
                  </>
                )}
                {/* <hr className="mt_100" /> */}
                <div
                  id="nav-fixed-height"
                  ref={this.navTopMenu}
                  onClick={this.handleWindowSizeChangeTimeset()}
                // style={{
                //   minHeight: navTopHeight > navStaticHeight ? 0 : navStaticHeight
                // }}
                >
                  {!isSettingPage && (
                    <>
                      {this.navLinks
                        .find((item) =>
                          isPermitted(item, this.props.detailRole, workspace)
                        ) &&
                        <Accordion
                          noLine
                          // alwaysOpen={sidebarStatus}
                          isOpen={this.state.openAll}
                          isChanged={this.state.isChanged}
                          title="TRAIN"
                          description={
                            <>
                              {!isSettingPage &&
                                this.navLinks
                                  .filter((item) =>
                                    isPermitted(item, this.props.detailRole, workspace)
                                  )
                                  .map((row, index) => {
                                    if (row.shape === "line") {
                                      return <hr className="mt_100" />;
                                    }
                                    if (row.type === "ahref") {
                                      return (
                                        <div key={index} className="nav-item">
                                          <div>
                                            {sidebarStatus &&
                                              !this.state.isActive && (
                                                <div
                                                  className="nav-item__tooltip"
                                                  style={{
                                                    marginTop:
                                                      -this.state.scrollTop,
                                                  }}
                                                >
                                                  <p className="app-tooltip">
                                                    {row.name}
                                                  </p>
                                                </div>
                                              )}
                                            <ahref
                                              onClick={() => {
                                                this.handleWindowSizeChangeTimeset();
                                                row.link();
                                              }}
                                              key={index}
                                              className={this.getActiveClass(
                                                row.link,
                                                row.class
                                              )}
                                            >
                                              {row.icon}
                                              {row.name}
                                            </ahref>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div key={index} className="nav-item">
                                        <div>
                                          {sidebarStatus && !this.state.isActive && (
                                            <div
                                              className="nav-item__tooltip"
                                              style={{
                                                marginTop: -this.state.scrollTop,
                                              }}
                                            >
                                              <p className="app-tooltip">
                                                {row.name}
                                              </p>
                                            </div>
                                          )}
                                          <Link
                                            to={`/${workspace && slugify(workspace.name)
                                              }${row.link}`}
                                            onClick={
                                              this.handleWindowSizeChangeTimeset
                                            }
                                            key={index}
                                            className={this.getActiveClass(
                                              row.link,
                                              row.class
                                            )}
                                          >
                                            {row.icon}
                                            {row.name}
                                            {!sidebarStatus && row.beta && <span
                                              className={classNames("primary-nav__beta", {

                                              })}
                                            >
                                              Beta
                                            </span>
                                            }
                                          </Link>
                                        </div>
                                      </div>
                                    );
                                  })}
                            </>
                          }
                        />
                      }
                      {this.navLinks1
                        .find((item) =>
                          isPermitted(item, this.props.detailRole)
                        ) &&
                        <Accordion
                          // alwaysOpen={sidebarStatus}
                          noLine
                          isOpen={this.state.openAll}
                          isChanged={this.state.isChanged}
                          title="DEVELOP"
                          description={
                            <>
                              {!isSettingPage &&
                                this.navLinks1
                                  .filter((item) =>
                                    isPermitted(item, this.props.detailRole)
                                  )
                                  .map((row, index) => {
                                    if (row.shape === "line") {
                                      return <hr className="mt_100" />;
                                    }
                                    if (row.type === "ahref") {
                                      return (
                                        <div key={index} className="nav-item">
                                          <div>
                                            {sidebarStatus &&
                                              !this.state.isActive && (
                                                <div
                                                  className="nav-item__tooltip"
                                                  style={{
                                                    marginTop:
                                                      -this.state.scrollTop,
                                                  }}
                                                >
                                                  <p className="app-tooltip">
                                                    {row.name}
                                                  </p>
                                                </div>
                                              )}
                                            <ahref
                                              onClick={() => {
                                                this.handleWindowSizeChangeTimeset();
                                                row.link();
                                              }}
                                              key={index}
                                              className={this.getActiveClass(
                                                row.link,
                                                row.class
                                              )}
                                            >
                                              {row.icon}
                                              {row.name}
                                            </ahref>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div key={index} className="nav-item">
                                        <div>
                                          {sidebarStatus && !this.state.isActive && (
                                            <div
                                              className="nav-item__tooltip"
                                              style={{
                                                marginTop: -this.state.scrollTop,
                                              }}
                                            >
                                              <p className="app-tooltip">
                                                {row.name}
                                              </p>
                                            </div>
                                          )}
                                          <Link
                                            to={`/${workspace && slugify(workspace.name)
                                              }${row.link}`}
                                            onClick={
                                              this.handleWindowSizeChangeTimeset
                                            }
                                            key={index}
                                            className={this.getActiveClass(
                                              row.link,
                                              row.class
                                            )}
                                          >
                                            {row.icon}
                                            {row.name}
                                          </Link>
                                        </div>
                                      </div>
                                    );
                                  })}
                            </>
                          }
                        />
                      }
                      {this.navLinks2
                        .find((item) =>
                          isPermitted(item, this.props.detailRole)
                        ) &&
                        <Accordion
                          // alwaysOpen={sidebarStatus}
                          noLine
                          isOpen={this.state.openAll}
                          isChanged={this.state.isChanged}
                          title="DEPLOY"
                          description={
                            <>
                              {!isSettingPage &&
                                this.navLinks2
                                  .filter((item) =>
                                    isPermitted(item, this.props.detailRole)
                                  )
                                  .map((row, index) => {
                                    if (row.shape === "line") {
                                      return <hr className="mt_100" />;
                                    }
                                    if (row.type === "ahref") {
                                      return (
                                        <div key={index} className="nav-item">
                                          <div>
                                            {sidebarStatus &&
                                              !this.state.isActive && (
                                                <div
                                                  className="nav-item__tooltip"
                                                  style={{
                                                    marginTop:
                                                      -this.state.scrollTop,
                                                  }}
                                                >
                                                  <p className="app-tooltip">
                                                    {row.name}
                                                  </p>
                                                </div>
                                              )}
                                            <ahref
                                              onClick={() => {
                                                this.handleWindowSizeChangeTimeset();
                                                row.link();
                                              }}
                                              key={index}
                                              className={this.getActiveClass(
                                                row.link,
                                                row.class
                                              )}
                                            >
                                              {row.icon}
                                              {row.name}
                                            </ahref>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div key={index} className="nav-item">
                                        <div>
                                          {sidebarStatus && !this.state.isActive && (
                                            <div
                                              className="nav-item__tooltip"
                                              style={{
                                                marginTop: -this.state.scrollTop,
                                              }}
                                            >
                                              <p className="app-tooltip">
                                                {row.name}
                                              </p>
                                            </div>
                                          )}
                                          <Link
                                            to={`/${workspace && slugify(workspace.name)
                                              }${row.link}`}
                                            onClick={
                                              this.handleWindowSizeChangeTimeset
                                            }
                                            key={index}
                                            className={this.getActiveClass(
                                              row.link,
                                              row.class
                                            )}
                                          >
                                            {row.icon}

                                            {row.name}
                                            {!sidebarStatus && row.deviceEnrollment && !!pendingDevices.length &&
                                              <span
                                                className={classNames("primary-nav__number", {
                                                })}
                                              >
                                                {pendingDevices.length || 0}
                                              </span>}
                                          </Link>
                                        </div>
                                      </div>
                                    );
                                  })}
                            </>
                          }
                        />
                      }
                      {
                        this.navLinks3
                          .find((item) =>
                            isPermitted(item, this.props.detailRole)
                          )
                        &&
                        <Accordion
                          // alwaysOpen={sidebarStatus}
                          noLine
                          isOpen={this.state.openAll}
                          isChanged={this.state.isChanged}
                          title="OPERATE"
                          description={
                            <>
                              {!isSettingPage &&
                                this.navLinks3
                                  .filter((item) =>
                                    isPermitted(item, this.props.detailRole)
                                  )
                                  .map((row, index) => {
                                    if (row.shape === "line") {
                                      return <hr className="mt_100" />;
                                    }
                                    if (row.type === "ahref") {
                                      return (
                                        <div key={index} className="nav-item">
                                          <div>
                                            {sidebarStatus &&
                                              !this.state.isActive && (
                                                <div
                                                  className="nav-item__tooltip"
                                                  style={{
                                                    marginTop:
                                                      -this.state.scrollTop,
                                                  }}
                                                >
                                                  <p className="app-tooltip">
                                                    {row.name}
                                                  </p>
                                                </div>
                                              )}
                                            <ahref
                                              onClick={() => {
                                                this.handleWindowSizeChangeTimeset();
                                                row.link();
                                              }}
                                              key={index}
                                              className={this.getActiveClass(
                                                row.link,
                                                row.class
                                              )}
                                            >
                                              {row.icon}

                                              {row.name}
                                            </ahref>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return (
                                      <div key={index} className="nav-item">
                                        <div>
                                          {sidebarStatus && !this.state.isActive && (
                                            <div
                                              className="nav-item__tooltip"
                                              style={{
                                                marginTop: -this.state.scrollTop,
                                              }}
                                            >
                                              <p className="app-tooltip">
                                                {row.name}
                                              </p>
                                            </div>
                                          )}
                                          <Link
                                            to={`/${workspace && slugify(workspace.name)
                                              }${row.link}`}
                                            onClick={
                                              this.handleWindowSizeChangeTimeset
                                            }
                                            key={index}
                                            className={this.getActiveClass(
                                              row.link,
                                              row.class
                                            )}
                                          >
                                            {row.icon}
                                            {row.name}
                                          </Link>

                                        </div>
                                      </div>
                                    );
                                  })}
                            </>
                          }
                        />
                      }
                    </>
                  )}
                  {isSettingPage && (
                    <>
                      <nav className="primary-nav">
                        <div className="nav-item mx-b-20">
                          <Link
                            to="/"
                            className={`primary-nav__link primary-nav__link_${DEFAULT_ACCENT}`}
                          >
                            <ArrowLeft />
                            <FormattedMessage {...appMessages.back} />
                          </Link>
                        </div>
                      </nav>
                      {this.navLinksSetting
                        .filter((item) =>
                          isPermitted(item, this.props.detailRole)
                        )
                        &&
                        <Accordion
                          noLine
                          // alwaysOpen={sidebarStatus}
                          isOpen={this.state.openSettingAll}
                          title="PERSONAL"
                          description={
                            <>
                              {this.navLinksSetting
                                .filter((item) =>
                                  isPermitted(item, this.props.detailRole)
                                )
                                .map((row, index) => {
                                  if (row.shape === "line") {
                                    return (
                                      <hr key={`${index}-0`} className="mt_100" />
                                    );
                                  }

                                  if (row.type === "ahref") {
                                    return (
                                      <div key={index} className="nav-item">
                                        {sidebarStatus && !this.state.isActive && (
                                          <div
                                            className="nav-item__tooltip"
                                            style={{
                                              marginTop: -this.state.scrollTop,
                                            }}
                                          >
                                            <p className="app-tooltip">
                                              {row.name}
                                            </p>
                                          </div>
                                        )}
                                        <ahref
                                          onClick={() => {
                                            this.handleWindowSizeChangeTimeset();
                                            row.link();
                                          }}
                                          key={index}
                                          className={this.getActiveClass(
                                            row.link,
                                            row.class
                                          )}
                                        >
                                          {row.icon}
                                          {row.name}
                                        </ahref>
                                      </div>
                                    );
                                  }

                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${workspace && slugify(workspace.name)
                                          }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </>
                          }
                        />
                      }
                      {this.navLinksSetting1
                        .find((item) =>
                          isPermitted(item, this.props.detailRole)
                        ) &&
                        <Accordion
                          // alwaysOpen={sidebarStatus}
                          noLine
                          isOpen={this.state.openSettingAll}
                          isChanged={this.state.isChanged}
                          title="SECURITY"
                          description={
                            <>
                              {this.navLinksSetting1
                                .filter((item) =>
                                  isPermitted(item, this.props.detailRole)
                                )
                                .map((row, index) => {
                                  if (row.shape === "line") {
                                    return <hr className="mt_100" />;
                                  }

                                  if (row.type === "ahref") {
                                    return (
                                      <div key={index} className="nav-item">
                                        {sidebarStatus && !this.state.isActive && (
                                          <div
                                            className="nav-item__tooltip"
                                            style={{
                                              marginTop: -this.state.scrollTop,
                                            }}
                                          >
                                            <p className="app-tooltip">
                                              {row.name}
                                            </p>
                                          </div>
                                        )}
                                        <ahref
                                          onClick={() => {
                                            this.handleWindowSizeChangeTimeset();
                                            row.link();
                                          }}
                                          key={index}
                                          className={this.getActiveClass(
                                            row.link,
                                            row.class
                                          )}
                                        >
                                          {row.icon}
                                          {row.name}
                                        </ahref>
                                      </div>
                                    );
                                  }

                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${workspace && slugify(workspace.name)
                                          }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </>
                          }
                        />
                      }
                      {this.navLinksSetting2
                        .find((item) =>
                          isPermitted(item, this.props.detailRole)
                        ) &&
                        <Accordion
                          // alwaysOpen={sidebarStatus}
                          noLine
                          isOpen={this.state.openSettingAll}
                          isChanged={this.state.isChanged}
                          title="ACCOUNT"
                          description={
                            <>
                              {this.navLinksSetting2
                                .filter((item) =>
                                  isPermitted(item, this.props.detailRole)
                                )
                                .map((row, index) => {
                                  if (row.shape === "line") {
                                    return <hr className="mt_100" />;
                                  }

                                  if (row.type === "ahref") {
                                    return (
                                      <div key={index} className="nav-item">
                                        {sidebarStatus && !this.state.isActive && (
                                          <div
                                            className="nav-item__tooltip"
                                            style={{
                                              marginTop: -this.state.scrollTop,
                                            }}
                                          >
                                            <p className="app-tooltip">
                                              {row.name}
                                            </p>
                                          </div>
                                        )}
                                        <ahref
                                          onClick={() => {
                                            this.handleWindowSizeChangeTimeset();
                                            row.link();
                                          }}
                                          key={index}
                                          className={this.getActiveClass(
                                            row.link,
                                            row.class
                                          )}
                                        >
                                          {row.icon}
                                          {row.name}
                                        </ahref>
                                      </div>
                                    );
                                  }

                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${workspace && slugify(workspace.name)
                                          }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </>
                          }
                        />
                      }
                      {this.navLinksSetting3
                        .find((item) =>
                          isPermitted(item, this.props.detailRole)
                        ) &&
                        <Accordion
                          noLine
                          // alwaysOpen={sidebarStatus}
                          title="MORE"
                          isOpen={this.state.openSettingAll}
                          isChanged={this.state.isChanged}
                          description={
                            <>
                              {this.navLinksSetting3
                                .filter((item) =>
                                  isPermitted(item, this.props.detailRole)
                                )
                                .filter((item) => {
                                  if (
                                    item.id === "partner" &&
                                    (!vendor.details ||
                                      (vendor.details &&
                                        vendor.details.status === "inactive"))
                                  ) {
                                    return false;
                                  }

                                  return item;
                                })
                                .map((row, index) => {
                                  if (row.shape === "line") {
                                    return <hr className="mt_100" />;
                                  }

                                  if (row.type === "ahref") {
                                    return (
                                      <div key={index} className="nav-item">
                                        {sidebarStatus && !this.state.isActive && (
                                          <div
                                            className="nav-item__tooltip"
                                            style={{
                                              marginTop: -this.state.scrollTop,
                                            }}
                                          >
                                            <p className="app-tooltip">
                                              {row.name}
                                            </p>
                                          </div>
                                        )}
                                        <ahref
                                          onClick={() => {
                                            this.handleWindowSizeChangeTimeset();
                                            row.link();
                                          }}
                                          key={index}
                                          className={this.getActiveClass(
                                            row.link,
                                            row.class
                                          )}
                                        >
                                          {row.icon}
                                          {row.name}
                                        </ahref>
                                      </div>
                                    );
                                  }

                                  return (
                                    <div key={index} className="nav-item">
                                      {sidebarStatus && !this.state.isActive && (
                                        <div
                                          className="nav-item__tooltip"
                                          style={{
                                            marginTop: -this.state.scrollTop,
                                          }}
                                        >
                                          <p className="app-tooltip">
                                            {row.name}
                                          </p>
                                        </div>
                                      )}
                                      <Link
                                        to={`/${workspace && slugify(workspace.name)
                                          }${row.link}`}
                                        onClick={
                                          this.handleWindowSizeChangeTimeset
                                        }
                                        key={index}
                                        className={this.getActiveClass(
                                          row.link,
                                          row.class
                                        )}
                                      >
                                        {row.icon}
                                        {row.name}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </>
                          }
                        />
                      }
                    </>
                  )}
                </div>
                <div className="primary-nav_hr mobile-only">
                  <hr className="mt_100" />
                </div>
                {this.props.detailRole && me && (
                  <nav className={navBottomClass} ref={this.navBottomMenu}>
                    {me &&
                      workspace &&
                      !isSettingPage &&
                      workspace.showGetStartedPanel &&
                      (me.role.name === "Owner" ||
                        me.role.name === "Admin") && (
                        <div
                          className={navIconClass}
                          onClick={() =>
                            this.updateShowGetStarted(
                              !this.state.showGetStartedModal
                            )
                          }
                        >
                          <div className="left-panel-icon">
                            <CircularProgressbar
                              strokeWidth={13}
                              value={isCompleted ? 0 : value}
                              maxValue={tracker && tracker.taskCount}
                            // text={tracker &&
                            //   tracker.taskCount - tracker.completedCount}
                            />
                          </div>
                          {(tracker && tracker.taskCount) !==
                            (tracker && tracker.completedCount) && (
                              <div className="get-started-text">
                                {tracker &&
                                  tracker.taskCount - tracker.completedCount}
                              </div>
                            )}
                          {(tracker && tracker.taskCount) ===
                            (tracker && tracker.completedCount) && (
                              <div className="get-started-success">
                                <Check />
                              </div>
                            )}
                          <a
                            href="javascript:void(0)"
                            className={`primary-nav__link_${DEFAULT_ACCENT} left-panel-text`}
                          >
                            <div>
                              <div className="left-panel-title mx-b-3">
                                <FormattedMessage {...appMessages.setup} />
                                &nbsp;Viso Suite
                              </div>
                              <h5 className="left-panel-desc">
                                {(tracker &&
                                  tracker.taskCount - tracker.completedCount) ||
                                  "0"}{" "}
                                <FormattedMessage
                                  {...appMessages.remainingTasks}
                                />
                              </h5>
                            </div>
                          </a>
                        </div>
                      )}
                  </nav>
                )}
                <div className={classNames("nav-footer", {
                  'nav-footer-fixed': navTopHeight + 260 < navStaticHeight
                })}>
                  <button
                    type="button"
                    className="sidebar__toggle not-mobile h4"
                    onClick={() =>
                      this.props.updateSidebarDisplay(!sidebarStatus)
                    }
                  >
                    <RightCevronIcon />
                    <FormattedMessage {...appMessages.collapse} />
                  </button>

                  {!sidebarStatus && (
                    <div className="nav-footer-menu">
                      <div type="button" onClick={this.onExpandAll}>
                        <ChevronsDown />
                      </div>
                      <div onClick={this.onCollapseAll} className="">
                        <ChevronsUp />
                      </div>
                    </div>
                  )}
                </div>
              </nav>
            </Scrollbars>
            <div
              className={classNames("", {
                "primary-nav-scroll": this.state.showButtonBox,
              })}
            />
          </div>
        </aside>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  tracker: getTrackerInfo(),
  deviceSizeType: makeSelectDevice(),
  detailRole: getDetailRole(),
  me: makeSelectMe(),
  vendor: getVendorDetailsState(),
  notifications: getNotifications(),
  loadingTracker: makeSelectLoadingTracker(),
  sidebarStatus: getSidebarStatus(),
  workspace: makeSelectFetchWorkspace(),
  workspaces: makeSelectUserWorkspace(),
  showNotification: getNotificationState(),
  pendingDevices: getPendingDeviceList(),
});

const withConnect = connect(mapStateToProps, {
  logout,
  getPendingDeviceRequest,
  getStartedTrackerRequest,
  updateDeviceType,
  selectWorkspaceRequest,
  updateNotificationDisplay,
  updateSidebarDisplay,
  updateStartedTrackerRequest,
});

LeftPanel.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  workspaces: PropTypes.any,
  workspace: PropTypes.object,
  sidebarStatus: PropTypes.bool,
  selectWorkspaceRequest: PropTypes.func,
};

const withReducer = injectReducer({ key: "global", reducer });
const withLoginReducer = injectReducer({ key: "login", reducer: loginReducer });
const withLoginSaga = injectSaga({ key: "login", saga: loginSaga });

const withDepSaga = injectSaga({ key: 'deployment', saga: sagaDep });
const withDepReducer = injectReducer({
  key: 'deployment',
  reducer: reducerDep,
});

export default withRouter(
  compose(withReducer, withLoginReducer, withLoginSaga, withDepReducer, withDepSaga, withConnect)(LeftPanel)
);
